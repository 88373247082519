import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

export const renderProductsCountColumn = (renderColumnTitle: (title: JSX.Element) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Products_count"
      title={renderColumnTitle(<>Товаров <br/> в категории</>)}
      dataIndex="productsInCategory"
      width={110}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: number) => <TextWithTooltip text={value?.toString() ?? "0"} />}
    />
  );
};
