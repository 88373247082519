import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { ImportTaskType } from "importTask/types";
import { EntityType } from "../CatalogTable";

export const renderCatalogueTypeColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  onClickHandler?: (...args: any[]) => void,
): JSX.Element => {
  return (
    <Column
      key="Catalogue_type"
      title={renderColumnTitle("Где загружено")}
      dataIndex="catalog"
      width={250}
      render={(value, entity: EntityType) => {
        const { id, status, source, is_periodic } = entity as ImportTaskType || {};

        const isNavigateToEdit: boolean = "is_periodic" in entity && !is_periodic && status === "Черновик";

        return (
          <div
            className={isNavigateToEdit && !!onClickHandler ? "cursor-pointer" : ""}
            onClick={() => isNavigateToEdit && onClickHandler?.(id, source)}
          >
            {value}
          </div>
        );
      }}
    />
  );
};
