import { FC, JSX, useEffect } from "react";
import { useWatch } from "antd/es/form/Form";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
  deleteImportTask,
  fetchConfigColumns,
  fetchImportTask,
  updateImportTask
} from "redux/thunks/importTask.thunk";
import { setCurrentTable } from "redux/slices/importTaskSlice";

// utils
import useCancelableDebounce from "utils/useCancelableDebounce";

// components
import { Button, Form, Input, message, notification } from "antd";
import PagePanel from "components/ui/Panel/PagePanel";
import PanelBlock from "components/ui/Panel/PanelBlock";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import MatchingColumnsBlock from "components/MatchingTableColumns/MatchingColumnsBlock/MatchingColumnsBlock";
import PanelLayout from "components/ui/Panel/PanelLayout";
import { LoadingOutlined } from "@ant-design/icons";

import css from "./style.module.css";

// types
import {
  FieldType,
  ImportTaskColumnType,
  ImportTaskRequestType,
  ImportTaskType,
  ImportTaskUpdateRequestType,
} from "importTask/types";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { DefaultOptionType } from "antd/es/select";

export type MatchingTableColumnsFormType = {
  taskName: string;
  firstLineSkip: boolean;
  [key: `columnType-${string}`]: DefaultOptionType | undefined;
}

const MatchingTableColumns: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();
  const [form] = Form.useForm<MatchingTableColumnsFormType>();
  const values = useWatch<MatchingTableColumnsFormType>([], form);

  const { taskId } = useParams();

  const {
    selected: importTask,
    columns,
    isFetching
  } = useSelector((state: AppStateType) => state.importTaskSlice);

  useEffect(() => {
    dispatch(fetchConfigColumns()).catch((error) => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    const needData: boolean = !!taskId && !importTask;
    const needUpdate: boolean = !!taskId && importTask?.id !== taskId;

    if (needData || needUpdate) {
      const searchParams: ImportTaskRequestType = {
        import_task_id: taskId,
      };

      fetchTask(searchParams);
    }
  }, [taskId]);

  useEffect(() => {
    if (importTask?.name) {
      form.setFieldsValue({ taskName: importTask?.name });
    }
  }, [importTask]);

  const navigateToImportTasks = (): void => {
    dispatch(setCurrentTable("sources"));
    navigate("/import/1");
  };

  const { debouncedFunction: fetchTask } = useCancelableDebounce((params, token) => {
    dispatch(fetchImportTask(params as ImportTaskRequestType, token))
      .catch(console.error);
  });

  const handlerDeleteImportTask = (): void => {
    dispatch(deleteImportTask({ import_task_id: taskId }))
      .then(() => {
        message.success("Задача успешно удалена");
        navigateToImportTasks();
      })
      .catch(() => message.error("Ошибка при удалении задачи"));
  };

  const handleSubmit = (isNew: boolean): void => {
    const values = form.getFieldsValue();
    const { firstLineSkip, taskName } = values;

    const newFields: FieldType[] = importTask?.fields_map?.map(({ name, value }, index) => {
      const columnType: string = values?.[`columnType-${index}`]?.toString() || null;

      return {
        name,
        value: value?.filter((item: string) => Boolean(item)),
        type: columnType,
      };
    });

    const params: ImportTaskRequestType = {
      import_task_id: taskId,
    };

    const body: ImportTaskUpdateRequestType = {
      name: taskName,
      ...(isNew ? { status: "Новый" } : {}),
      first_line_skip: firstLineSkip,
      fields_map: newFields,
    };

    dispatch(updateImportTask(params, body))
      .then((_response: ImportTaskType) => {
        message.success("Изменения успешно сохранены");
        navigateToImportTasks();
      })
      .catch((error) => {
        notification.error({
          message: "Произошла ошибка",
          description: "При разборе таблицы произошла ошибка. Пожалуйста, повторите попытку позже."
        });
        console.error(error);
      });
  };

  const disableSubmitBtn = (): boolean => {
    const getKeys = (columnType: string): string[] => columns?.[columnType]
      ?.flatMap((column: ImportTaskColumnType) => Object.keys(column));

    const columnKeys: string[] = Object.keys(values || {})?.filter((key: string) => key?.startsWith("columnType-"));
    const columnValues: string[] = columnKeys.map((key: string) => values?.[key]?.value);
    const allRequiredKeysFilled: boolean = getKeys("required")?.every((key: string) => columnValues.includes(key));
    const someOptionalKeyFilled: boolean = getKeys("optional")?.some((key: string) => columnValues.includes(key));

    return !values?.taskName || !allRequiredKeysFilled || !someOptionalKeyFilled;
  };

  const renderHeader = (): JSX.Element => {
    return (
      <PanelLayout>
        <PagePanel className="flex justify-between gap-4">
          <PanelBlock title={{ content: "Название задачи сопоставления", isRequired: true }}>
            <Form.Item name="taskName" className="m-0">
              <Input
                placeholder="Введите название задачи"
                required
              />
            </Form.Item>
          </PanelBlock>
          <PanelBlock title={{ content: "Файл таблицы" }}>
            <a
              href={importTask?.file_link || "#"}
              target="_blank"
              rel="noreferrer"
              className={css.link}
            >
              {importTask?.filename}
            </a>
          </PanelBlock>
        </PagePanel>
      </PanelLayout>
    );
  };

  const renderFooter = (): JSX.Element => {
    return (
      <PagePanel>
        <div className="flex justify-end">
          <Button
            size="large"
            className="mr-2"
            onClick={handlerDeleteImportTask}
          >
            Отменить и удалить
          </Button>
          <Button
            size="large"
            className="mr-2"
            onClick={() => handleSubmit(false)}
          >
            Сохранить как черновик
          </Button>
          <PrimaryButton
            text="Подтвердить сопоставление"
            size="large"
            icon={isFetching ? <LoadingOutlined /> : null}
            isDisabled={disableSubmitBtn()}
            onClickHandler={() => !isFetching ? handleSubmit(true) : {}}
          />
        </div>
      </PagePanel>
    );
  };

  return (
    <Form form={form}>
      {renderHeader()}
      <MatchingColumnsBlock form={form} />
      {renderFooter()}
    </Form>
  );
};

export default MatchingTableColumns;
