import React, { JSX, FC, useState } from "react";
import { ProductMediaType } from "app/types";
import { Divider, FormInstance } from "antd";
import { ReactComponent as HelpOutlinedIcon } from "../../../assets/icons/help_outlined_icon.svg";

// components

import DragAndDrop, { DraggerFileType } from "components/ui/DragAndDrop/DragAndDrop";
import { transformLoadedFiles } from "components/ui/DragAndDrop/transformLoadedFiles";
import MediaRequirementsModal from "components/modals/MediaRequirementsModal/MediaRequirementsModal";
import { ProductType } from "products/types";

interface IPhotoBlockProps {
  form: FormInstance;
  addMedia: (file: DraggerFileType) => DraggerFileType;
  deleteMedia: (fileList: DraggerFileType[]) => DraggerFileType[];
  currentProduct: ProductType;
  resetFileList?: boolean;
  isDisabledFields: boolean;
}

const PhotoBlock: FC<IPhotoBlockProps> = ({
  form,
  addMedia,
  deleteMedia,
  currentProduct,
  isDisabledFields
}): JSX.Element => {
  const [
    isShowRequirementsModal,
    setIsShowRequirementsModal
  ] = useState<boolean>(false);

  const { getFieldValue, setFieldsValue } = form;

  const photos: ProductMediaType[] = currentProduct?.photos?.filter(({ filename }) => /\.(jpg|png)/.test(filename));

  const filterFilesFromDragger = (fileUid: string, valueName: string): DraggerFileType[] => {
    if ("fileList" in getFieldValue(valueName)) {
      return getFieldValue(valueName)?.fileList?.filter(i => i.uid !== fileUid);
    }

    return getFieldValue(valueName)?.filter(({ uid }) => uid !== fileUid);
  };

  const handleAfterUpload = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName);

    setFieldsValue({ [fieldName]: [...files, file] });
  };

  const handleOnRemove = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName);

    setFieldsValue({ [fieldName]: files, });
  };

  return (
    <div>
      <DragAndDrop
        type="photo"
        form={form}
        isMultiple
        action="/api/v1/temp_documents/create/"
        title="Фото"
        infoTitle={(
          <p className="flex items-center cursor-pointer blue-color" onClick={() => setIsShowRequirementsModal(true)}>
            Какими должны быть фото
            <HelpOutlinedIcon />
          </p>
        )}
        placeholder="Загрузите файлы или перетащите их сюда"
        showCounter
        isDisabled={isDisabledFields}
        maxQuantity={10}
        additionalAddRequest={addMedia}
        additionalDeleteRequest={deleteMedia}
        loadedFilelist={photos?.length
          ? transformLoadedFiles(photos)
          : []
        }
        resetFilesList={false}
        acceptedFileType={{ extensions: ["jpg", "png"], maxItemSize: 10 * 1024 * 1024 }}
        afterUpload={(file: DraggerFileType) => handleAfterUpload(file, "photo")}
        onRemove={(file: DraggerFileType) => handleOnRemove(file, "photo")}
      />
      {isShowRequirementsModal && (
        <MediaRequirementsModal
          isOpen={isShowRequirementsModal}
          onCancelHandler={() => setIsShowRequirementsModal(false)}
        />
      )}
    </div>
  );
};

export default PhotoBlock;
