import { FC, JSX } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router";

import MatchingTableColumns from "components/MatchingTableColumns/MatchingTableColumns";
import PageWrapper from "components/ui/PageWrapper";
import ImportMasterLayout from "components/ImportMaster/ImportMasterLayout";

const MatchingTableColumnsPage: FC = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const onCancelHandler = (): void => navigate("/import-master");

  return (
    <ImportMasterLayout title="Сопоставление колонок таблицы" cancelHandler={onCancelHandler}>
      <PageWrapper className="p-2 py-0 sm:px-2 md:px-8 lg:px-16 xl:px-24 2xl:px-32">
        <MatchingTableColumns />
      </PageWrapper>
    </ImportMasterLayout>
  );
};

export default MatchingTableColumnsPage;
