import React, { FC, useState, useEffect } from "react";
import { useWatch } from "antd/es/form/Form";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";

// components
import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";
import PagePanel from "components/ui/Panel/PagePanel";
import { Checkbox, Form, FormInstance, Tooltip } from "antd";
import MatchingColumn from "components/MatchingTableColumns/MatchingColumn/MatchingColumn";

// icons
import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";

// types
import { DefaultOptionType } from "antd/es/select";
import { FieldType, ImportTaskColumnType } from "importTask/types";
import { MatchingTableColumnsFormType } from "components/MatchingTableColumns/MatchingTableColumns";
import InfoCard from "components/ui/InfoCard/InfoCard";

import css from "../../ImportMaster/ImportYML/style.module.scss";

interface IMatchingColumnsBlockProps {
  form: FormInstance<MatchingTableColumnsFormType>;
}

const MatchingColumnsBlock: FC<IMatchingColumnsBlockProps> = ({ form }) => {
  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Сопоставление колонок" },
    description: { content: "Выберите типы для колонок опираясь на данные из загруженной таблицы представленные ниже" }
  };

  const values = useWatch<MatchingTableColumnsFormType>([], form);

  const {
    selected: importTask,
    columns,
  } = useSelector((state: AppStateType) => state.importTaskSlice) || {};

  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>([]);
  const [firstLineSkip, setFirstLineSkip] = useState<boolean>(importTask?.first_line_skip ?? false);

  useEffect(() => {
    form.setFieldsValue({ firstLineSkip: importTask?.first_line_skip });
    setFirstLineSkip(importTask?.first_line_skip);
  }, [importTask]);

  useEffect(() => {
    columns && setSelectOptions(transformColumns(columns));
  }, [columns]);

  useEffect(() => {
    if (values) {
      const columnTypes: string[] = Object.keys(values)
        ?.filter((key: string) => key.includes("columnType") && !!values[key])
        ?.map((key: string) => values[key]);

      columns && setSelectOptions(transformColumns(columns)
        ?.filter(({ value }) => !columnTypes.includes(value as string)));
    }
  }, [values, columns]);

  const transformColumns = (data: ImportTaskColumnType): DefaultOptionType[] => {
    const formatColumnList = (columnList: Record<string, string>[]) =>
      columnList.map((column: Record<string, string>) =>
        Object.entries(column).map(([key, value]) => ({
          value: key,
          label: value,
        }))
      ).flat();

    return [
      ...formatColumnList(data?.required),
      ...formatColumnList(data?.optional),
      ...formatColumnList(data?.not_required),
    ];
  };

  const handleCheck = (e: CheckboxChangeEvent): void => {
    form.setFieldsValue({ firstLineSkip: e.target.checked });
    setFirstLineSkip(e.target.checked);
  };

  return (
    <PagePanel>
      <PanelLayout {...PANEL_LAYOUT_PROPS}>
        <InfoCard
          text={{
            content: `Для успешного сопоставления необходим минимальный набор колонок:
            артикул, название, описание, цена, категория. Выберите соответствующие колонки ниже.
            Если эти данные отсутствуют в таблице, вернитесь назад и загрузите корректный файл.`,
          }}
          icon={{type: "exclamation", className: "mr-3"}}
          background="grey"
          className={css.infoCard}
        />
        <Form.Item name="firstLineSkip" className="mb-2">
          <div className="flex items-center">
            <Checkbox
              key="CheckFirstLineSkip"
              checked={firstLineSkip}
              onChange={handleCheck}
              className="m-0"
            >
              Пропустить первую строку
            </Checkbox>
            <Tooltip
              title="Пропустить первую строку в таблице, если она содержит заголовки
              столбцов (например, № товара, артикул, название, цена) и не должна обрабатываться."
            >
              <HelpIcon className={css.icon} />
            </Tooltip>
          </div>
        </Form.Item>
      </PanelLayout>
      {importTask?.fields_map?.map((field: FieldType, index: number) => {
        return (
          <MatchingColumn
            key={index}
            form={form}
            field={field}
            index={index}
            selectOptions={selectOptions}
          />
        );
      })}
    </PagePanel>
  );
};

export default MatchingColumnsBlock;
