import { JSX, FC, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { APP_PATHES } from "constants/appPathes";

// components
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import TabsWithCounters from "components/Catalog/TabsWithCounters/TabsWithCounters";
import { Button, Dropdown, MenuProps } from "antd";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down-icon.svg";

import css from "components/Header/HeaderUserInfo/style.module.css";

interface ICatalogPageProps { }

const CatalogPage: FC<ICatalogPageProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const [isShow, setIsShow] = useState<boolean>(false);

  const renderUploadProductButtons = (): JSX.Element => {
    const handleBtnClick = (): void => navigate(APP_PATHES.IMPORT_MASTER);

    const handleMenuItemClick = ({ key }): void => navigate(`${APP_PATHES.IMPORT_MASTER}/?mode=${key}`);

    const items: MenuProps["items"] = [
      { label: "Экспорт каталога в excel", key: "xls", onClick: handleMenuItemClick },
      { label: "Экспорт каталога в yml", key: "yml", onClick: handleMenuItemClick },
    ];

    return (
      <div className="flex">
        <PrimaryButton onClickHandler={handleBtnClick} text="Загрузить товары" />
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button size="large" className="ml-2" onClick={() => setIsShow(!isShow)}>
            <div className="flex items-center">
              <div className="mr-2">Еще</div>
              <ArrowDownIcon className={`${isShow ? css.rotate : ""}`} />
            </div>
          </Button>
        </Dropdown>
      </div>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className={`pr-6 pl-10 pt-8 sticky bg-white transition-all ease-in-out z-10`}>
        <header className="flex justify-between">
          <h3 className="mb-3">Мой каталог</h3>
          {renderUploadProductButtons()}
        </header>
      </div>
    );
  };

  return (
    <div className="mb-20">
      {renderHeader()}
      <TabsWithCounters />
    </div>
  );
};

export default CatalogPage;
