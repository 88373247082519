import { FC, useState } from "react";

import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";
import PanelBlock from "components/ui/Panel/PanelBlock";
import FileUploadButton from "components/FileUploadButton";

import { TemporaryDocumentType } from "tempDocuments/types";

interface IImportExcelProps { }

const ImportExcel: FC<IImportExcelProps> = () => {
  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Импорт таблицы Excel" },
    description: {
      content: <>Добавьте товары, используя прейскурант в Excel (XLS, CSV),
        после успешной загрузки она появится в списке задач по импорту.</>
    },
    className: "flex"
  };

  const [uploadFileData, setUploadFileData] = useState<TemporaryDocumentType>(null);

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <PanelBlock title={{ content: "Загрузка файла" }}>
        <FileUploadButton
          acceptType=".xls, .xlsx, .csv, .zip"
          description="Формат: XLS, CSV"
          setUploadedFileData={setUploadFileData}
        />
      </PanelBlock>
    </PanelLayout>
  );
};

export default ImportExcel;