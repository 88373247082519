import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { calcDeclensionByCount } from "../../../../utils/сalcDeclensionByCount";

export const renderPeriodicityColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Periodicity"
      dataIndex="update_period"
      width={100}
      title={renderColumnTitle("Регулярность обновления")}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: number) => (
        <div>
          {
            value ?
              `${value} ${calcDeclensionByCount(value, ["час", "часа", "часов"])}`
              : "-"
          }
        </div>
      )}
    />
  );
};
