import { FC, useState, useEffect, useCallback } from "react";
import { useWatch } from "antd/es/form/Form";

import {
  Radio,
  Checkbox,
  Input,
  Form,
  CheckboxOptionType,
  FormInstance,
  Tooltip,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";
import PanelBlock from "components/ui/Panel/PanelBlock";
import FileUploadButton from "components/FileUploadButton";

import css from "./style.module.scss";

import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";

import { RadioChangeEvent } from "antd/lib/radio";
import { TemporaryDocumentType } from "tempDocuments/types";
import { ImportMasterFormType } from "pages/ImportMasterPage";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

export const SETTINGS_TYPE = {
  isPeriodic: "is_periodic",
  updateRest: "rests",
  updatePrice: "prices",
  updateProperty: "attributes",
  clearProducts: "clear_existing",
  firstLineSkip: "first_line_skip",
  logIn: "log_in",
};

interface IImportYMLProps {
  initialSettings?: any;
}

const ImportYML: FC<IImportYMLProps> = () => {
  const form: FormInstance<ImportMasterFormType> = useFormInstance();
  const values = useWatch<ImportMasterFormType>([], form);

  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Загрузка через YML" },
    description: {
      content: <>
        YML (Yandex Market Language) - это стандарт, разработанный Yandex
        для приема и публикации информации в базе данных Yandex.Market.
        <a
          className="blue-color pl-2"
          href="https://yandex.ru/support/partnermarket/export/yml.html"
          target="blank"
        >
          Подробнее
        </a>
      </>
    },
    className: "flex flex-col md:flex-row gap-4"
  };

  const [uploadMode, setUploadMode] = useState<"file" | "url">("file");
  const [uploadSettings, setUploadSettings] = useState<any[]>([]);
  const [uploadedFile, setUploadedFile] = useState<TemporaryDocumentType>(null);

  useEffect(() => {
    !!uploadSettings && form.setFieldValue("settings", uploadSettings.map((value) => value));
  }, [form, uploadSettings]);

  const UploadViaFileBlock = useCallback((): JSX.Element => {
    return (
      <FileUploadButton
        acceptType=".yml, .xml"
        description="Формат: YML, XML"
        setUploadedFileData={setUploadedFile}
      />
    );
  }, []);

  const AuthSettingsBlock = useCallback((): JSX.Element => {
    return (
      values?.settings?.includes(SETTINGS_TYPE.logIn) && (
        <div className="auth_wrapper flex flex-col">
          <div className="flex flex-col gap-2">
            <div className="input_wrapper">
              <p><span className={css.red}>*</span> Логин для авторизации</p>
              <Form.Item className="m-0" name="username">
                <Input placeholder="Введите логин" size="middle" />
              </Form.Item>
            </div>
            <div className="input_wrapper">
              <p><span className={css.red}>*</span> Пароль</p>
              <Form.Item className="m-0" name="password">
                <Input
                  placeholder="Введите пароль"
                  type="password"
                  size="middle"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      )
    );
  }, [values?.settings]);

  const UploadPeriodicBlock = useCallback((): JSX.Element => {
    return (
      <div className="flex flex-col gap-2 w-full">
        <div className="upload_wrapper">
          <div className="input_wrapper">
            <p><span className={css.red}>*</span> Ссылка на файл YML</p>
            <Form.Item className="m-0" name="fileLink">
              <Input
                className="upload_input"
                placeholder="Введите ссылку на файл YML"
                suffix={<LinkOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                size="middle"
              />
            </Form.Item>
          </div>

          {values?.settings?.includes(SETTINGS_TYPE.isPeriodic) && (
            <p className="ghost">Файл загружается каждые 6 часов</p>
          )}
        </div>
        <AuthSettingsBlock />
      </div>
    );
  }, [values?.settings]);

  const FileUploadBlock = useCallback((): JSX.Element => {
    const onChangeHandler = (event: RadioChangeEvent): void => setUploadMode(event.target.value);

    return (
      <PanelBlock title={{ content: "Загрузка файла" }} className="gap-2">
        <Form.Item className="m-0" name="uploadMode">
          <Radio.Group
            onChange={onChangeHandler}
            value={uploadMode}
            defaultValue="file"
          >
            <Radio.Button value="file">Из файла</Radio.Button>
            <Radio.Button value="url">По ссылке</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {uploadMode === "file"
          ? <UploadViaFileBlock />
          : <UploadPeriodicBlock />
        }
      </PanelBlock>
    );
  }, [UploadPeriodicBlock, UploadViaFileBlock, uploadMode]);

  const SettingsBlock = useCallback((): JSX.Element => {
    const isUrlMode: boolean = uploadMode !== "url";

    const settingsList: CheckboxOptionType<any>[] = [
      {
        label: (
          <div className="flex justify-center">
            <p className="select-none">Периодичная загрузка</p>
            {isUrlMode && (
              <Tooltip title="Периодическая загрузка возможна только при загрузке каталога по ссылке">
                <HelpIcon className={`cursor-pointer ${css.icon}`} />
              </Tooltip>
            )}
          </div>
        ),
        value: SETTINGS_TYPE.isPeriodic,
        disabled: isUrlMode,
      },
      {
        label: <p className="select-none">Остатки</p>,
        value: SETTINGS_TYPE.updateRest,
      },
      {
        label: <p className="select-none">Цены</p>,
        value: SETTINGS_TYPE.updatePrice,
      },
      {
        label: <p className="select-none">Свойства</p>,
        value: SETTINGS_TYPE.updateProperty,
      },
      {
        label: <p className="select-none">Удалить ранее загруженные товары</p>,
        value: SETTINGS_TYPE.clearProducts,
      },
      {
        label: <p className="select-none">Авторизация для загрузки каталога YML</p>,
        value: SETTINGS_TYPE.logIn,
        disabled: isUrlMode,
      }
    ];

    return (
      <PanelBlock title={{ content: "Настройки" }}>
        <Form.Item className="m-0" name="settings">
          <Checkbox.Group
            options={settingsList}
            className="flex flex-col"
          />
        </Form.Item>
      </PanelBlock>
    );
  }, [uploadMode]);

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <FileUploadBlock />
      <SettingsBlock />
    </PanelLayout>
  );
};

export default ImportYML;