import React, { FC } from "react";
import css from "./style.module.scss";

interface IPagePanelProps {
  className?: string;
  children?: React.ReactNode;
}

const PagePanel: FC<IPagePanelProps> = ({ className = "", children }) => {
  return (
    <div className={`panel_wrapper bg-white p-5 mb-4 w-full ${css.rounded} ${className}`}>
      {children}
    </div>
  );
};

export default PagePanel;