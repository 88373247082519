import React, { JSX, FC } from "react";
import { Progress, Tooltip } from "antd";

// icons
import { ReactComponent as FileImageOutlined } from "../../../../assets/icons/file_image_outlined.svg";
import { ReactComponent as VideoCameraOutlined } from "../../../../assets/icons/video_camera_outlined.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";

import css from "../style.module.css";
import { DraggerFileType } from "../DragAndDrop";

interface IProgressContainerProps {
  file: DraggerFileType;
  fileType: "photo" | "video";
  onClickHandler: (file: DraggerFileType) => void;
  isProgressError: boolean;
}

const ProgressContainer: FC<IProgressContainerProps> = ({
  file,
  fileType,
  onClickHandler,
  isProgressError
}): JSX.Element => {
  const borderClassName: string = isProgressError ? css.borderError : css.borderNormal;

  const renderIcon = (): JSX.Element => {
    switch (fileType) {
      case "photo":
        return <FileImageOutlined className="danger-color" />;
      case "video":
        return <VideoCameraOutlined className="danger-color" />;
      default:
        return <></>;
    }
  };

  const errorMsg: string = file.error ? `Ошибка - ${file.error}` : "";

  return (
    <Tooltip title={errorMsg}>
      <div
        className={`relative w-full h-0 rounded-lg pb-[100%] ${borderClassName}`}
        title={file.name}
      >
        {!isProgressError ? (
          <Progress
            percent={file?.percent || 0}
            status={file?.status}
            type="line"
            size="small"
            showInfo={false}
            className="absolute inset-0 flex items-center justify-center w-auto"
            style={{margin: "0 8px"}}
          />
        ) : (
          <div className="absolute inset-0 flex flex-col justify-center items-center">
            {renderIcon()}
          </div>
        )}
        {isProgressError && (
          <DeleteIcon
            className={`absolute cursor-pointer ${css.deleteIcon}`}
            onClick={() => onClickHandler(file)}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default ProgressContainer;
