import React, { JSX, ReactNode } from "react";
import { EditPropsType, TableColumnType } from "../CatalogTable";

// columns
import { renderPhotoColumn } from "./renderPhotoColumn";
import { renderNameColumn } from "./renderNameColumn";
import { renderCategoryColumn } from "./renderCategoryColumn";
import { renderBKCategoryColumn } from "./renderBKCategoryColumn";
import { renderPriceColumn } from "./renderPriceColumn";
import { renderCreatedAtColumn } from "./renderCreatedAtColumn";
import { renderUpdatedAtColumn } from "./renderUpdatedAtColumn";
import { renderDownloadSourceColumn } from "./renderDownloadSourceColumn";
import { renderStatusColumn } from "./renderStatusColumn";
import { renderDateTimeColumn } from "./renderDateTimeColumn";
import { renderCatalogueTypeColumn } from "./renderCatalogueTypeColumn";
import { renderPeriodicityColumn } from "./renderPeriodicityColumn";
import { renderFileTypeColumn } from "./renderFileTypeColumn";
import { renderFileColumn } from "./renderFileColumn";
import { renderActionsColumn } from "./renderActionsColumn";
import { renderEditableBKCategoryColumn } from "./renderEditableBKCategoryColumn";
import { renderSupplierCategoryColumn } from "./renderSupplierCategoryColumn";
import { renderProductsCountColumn } from "./renderProductsCountColumn";

export const renderColumns = (
  column: TableColumnType,
  renderActions: (entity: any, idx: number) => ReactNode,
  onClickHandler?: (...args: any[]) => void,
  editOptions?: EditPropsType
): JSX.Element => {
  const renderColumnTitle = (title: string | JSX.Element): JSX.Element => (
    <span className="secondary-color">{title}</span>
  );

  const columnRenderFunctions: Record<TableColumnType, JSX.Element> = {
    photo: renderPhotoColumn(renderColumnTitle),
    name: renderNameColumn(renderColumnTitle),
    category: renderCategoryColumn(renderColumnTitle),
    category_supplier: renderSupplierCategoryColumn(renderColumnTitle),
    bk_category: renderBKCategoryColumn(renderColumnTitle),
    editable_bk_category: renderEditableBKCategoryColumn(renderColumnTitle, editOptions),
    price: renderPriceColumn(renderColumnTitle),
    created_at: renderCreatedAtColumn(renderColumnTitle),
    updated_at: renderUpdatedAtColumn(renderColumnTitle),
    download_source: renderDownloadSourceColumn(renderColumnTitle),
    status: renderStatusColumn(renderColumnTitle, onClickHandler),
    date_time: renderDateTimeColumn(renderColumnTitle, onClickHandler),
    catalogue_type: renderCatalogueTypeColumn(renderColumnTitle, onClickHandler),
    products_count: renderProductsCountColumn(renderColumnTitle),
    periodicity: renderPeriodicityColumn(renderColumnTitle),
    source: renderFileTypeColumn(renderColumnTitle, onClickHandler),
    file: renderFileColumn(renderColumnTitle),
    actions: renderActionsColumn(renderActions),
  };

  return columnRenderFunctions[column];
};
