import React, { FC, ReactNode } from "react";

import css from "./style.module.scss";

interface IPageWrapper {
  className?: string;
  children?: ReactNode;
}

const PageWrapper: FC<IPageWrapper> = ({
  className,
  children
}) => {
  return (
    <div className={`${css.pageWrapper} flex flex-col h-full p-4 pt-8 gap-4 ${className || ""}`}>
      {children}
    </div>
  );
};

export default PageWrapper;