import React, { JSX, FC } from "react";

import { getFirstImage } from "components/Products/getFirstImage";
import ImageContainer from "components/ui/ImageContainer/ImageContainer";

import css from "./style.module.scss";

import { ProductType } from "products/types";
import { Tooltip } from "antd";

interface IModificationCardProps {
  product: ProductType;
  resetForm: () => void;
  setModificationId: React.Dispatch<React.SetStateAction<string>>;
  selectedModification: string;
  setSelectedModification: React.Dispatch<React.SetStateAction<string>>
}

const ModificationCard: FC<IModificationCardProps> = ({
  product,
  resetForm,
  setModificationId,
  selectedModification,
  setSelectedModification
}): JSX.Element => {
  const isSelected: boolean = product.id === selectedModification;

  const onSelectCard = (cardId: string): void => {
    setSelectedModification(cardId);
    setModificationId(cardId);
    resetForm();
  };

  return (
    <div
      className={`
        modificationCard_wrapper
        flex flex-col w-full bg-white card-radius px-4 py-3 mb-2 cursor-pointer overflow-hidden
        ${css.card} 
        ${isSelected ? css.selected : ""}
      `}
      onClick={() => !isSelected && onSelectCard(product.id)}
      id={product.id}
    >
      <div className={`flex justify-between items-start ${css.header}`}>
        <div className="flex items-center">
          <div className={css.logo}>
            <ImageContainer
              alt="Фото"
              src={getFirstImage(product)?.src}
              key={getFirstImage(product)?.filename}
            />
          </div>
          <div className={`ml-3 ${css.body}`}>
            <div className="secondary-color">{product.sku}</div>
            <div className="text-14-m overflow-hidden lg:text-nowrap">
              <Tooltip title={product?.name?.length > 20} >
                {product.name}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModificationCard;
