import { ProductMediaType } from "app/types";
import { ProductType } from "products/types";

export const getFirstImage = (product: ProductType): { src: string; filename: string } | null => {
  const imageMedia: ProductMediaType[] = product?.photos?.filter(
    ({ attachment }) => attachment.endsWith(".png") || attachment.endsWith(".jpg"),
  );

  if (imageMedia?.length > 0) {
    return { src: imageMedia[0].attachment, filename: imageMedia[0].filename };
  }

  return null;
};
