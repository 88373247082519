import React, { JSX, FC } from "react";
import { Tooltip } from "antd";

interface ITextWithTooltipProps {
  text: string | null | undefined;
  length?: number;
  className?: string;
}

const TextWithTooltip: FC<ITextWithTooltipProps> = ({
  text,
  length = 30,
  className = "",
}): JSX.Element => {
  return (
    !!text && (
      <Tooltip title={text?.length > length ? text : null}>
        <span className={`text-ellipsis overflow-hidden break-words line-clamp-2 inline-block ${className}`}>
          {text}
        </span>
      </Tooltip>
    )
  );
};

export default TextWithTooltip;
