import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import {
  ImportTaskLinkRequestType,
  ImportTaskFileRequestType,
  PaginatedImportTasksRequestType,
  ImportTaskRequestType,
  PaginatedImportTasksType,
  ImportTaskType,
  ImportTaskUpdateRequestType,
  ImportTaskColumnType,
} from "importTask/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

/** Загрузка файла YML из источника */
export const postImportTaskYMLSource = (
  body: ImportTaskLinkRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ImportTaskType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/yml/source/", body, cancelToken);
};

/** Загрузка файла XLS из источника */
export const postImportTaskXLSSource = (
  body: ImportTaskLinkRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ImportTaskType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/xls/source/", body, cancelToken);
};

/** Загрузка файла YML */
export const postImportTaskYMLFile = (
  body: ImportTaskFileRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ImportTaskType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/yml/file/", body, cancelToken);
};

/** Загрузка файла XLS */
export const postImportTaskXLSFile = (
  body: ImportTaskFileRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ImportTaskType>> => {
  return axios.POST(API_BASE, "/api/v1/import_task/xls/file/", body, cancelToken);
};

/** Получение списка заданий на импорт из файла */
export const getAllImportTasks = (
  params?: PaginatedImportTasksRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<PaginatedImportTasksType>> => {
  return axios.GET(API_BASE, "/api/v1/import_task/all/", params, cancelToken);
};

/** Получение задания на импорт из файла */
export const getImportTask = (
  params?: ImportTaskRequestType,
  cancelToken?: CancelToken
): Promise<
  AxiosResponse<ImportTaskType>
> => {
  return axios.GET(API_BASE, `/api/v1/import_task/${params.import_task_id}/`, null, cancelToken);
};

/** Обновление задания на импорт из файла */
export const updateImportTask = (
  params?: ImportTaskRequestType,
  body?: ImportTaskUpdateRequestType,
  cancelToken?: CancelToken
): Promise<
  AxiosResponse<ImportTaskType>
> => {
  return axios.PATCH(API_BASE, `/api/v1/import_task/${params.import_task_id}/`, body, cancelToken);
};

/** Удаление задания на импорт из файла */
export const deleteImportTask = (
  params?: ImportTaskRequestType,
  cancelToken?: CancelToken
): Promise<
  AxiosResponse<any>
> => {
  return axios.DELETE(API_BASE, `/api/v1/import_task/${params.import_task_id}/`, null, cancelToken);
};

/** Получение списка колонок XLS для сопоставления */
export const getXLSColumns = (
  cancelToken?: CancelToken
): Promise<AxiosResponse<ImportTaskColumnType>> => {
  return axios.GET(API_BASE, "/api/v1/import_task/xls/columns/", null, cancelToken);
};
