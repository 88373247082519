import { FC, useCallback } from "react";

import PagePanel from "components/ui/Panel/PagePanel";
import ImportYML from "components/ImportMaster/ImportYML";
import ImportExcel from "components/ImportMaster/ImportExcel";
import ImportManual from "components/ImportMaster/ImportManual";

export type ImportModeType = "yml" | "xls" | "manual";

interface IImportModePanelProps {
  importMode: ImportModeType;
};

const ImportModePanel: FC<IImportModePanelProps> = ({ importMode }) => {
  const ImportModeBlock = useCallback((): JSX.Element => {
    switch (importMode) {
      case "yml":
        return <ImportYML />;

      case "xls":
        return <ImportExcel />;

      case "manual":
        return <ImportManual />;

      default:
        return <ImportYML />;
    }
  }, [importMode]);

  return (
    <PagePanel className="flex flex-row gap-4">
      <ImportModeBlock />
    </PagePanel>
  );
};

export default ImportModePanel;