import React, { FC, JSX, useCallback, useEffect, useState } from "react";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import { Button, Cascader, Checkbox, Form, Input, message, Select } from "antd";
import PhotoBlock from "components/Products/PhotoBlock";
import { DraggerFileType } from "components/ui/DragAndDrop/DragAndDrop";
import GhostButton from "components/ui/GhostButton/GhostButton";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";

import "./style.scss";

import { ProductType } from "products/types";
import { useWatch } from "antd/es/form/Form";

import mockList from "components/Products/mockList.json";

interface IImportProductForm {
  product: ProductType;
}

const ImportProductForm: FC<IImportProductForm> = ({ product }) => {
  const form = useFormInstance();
  const values = useWatch([], form);

  const [options, setOptions] = useState<any[]>(mockList.options);

  const loadData = async (): Promise<void> => {
  };

  const getRequestBody = (): any => {
  };

  const fillValidator = async (_, properties): Promise<void> => {
    if (
      !properties
      || properties.some(({ propertyName, propertyValue }) => !propertyName || !propertyValue)
    ) {
      return Promise.reject(
        new Error("Все свойства должны быть заполнены")
      );
    }
    return Promise.resolve();
  };

  // Media
  const addMedia = (file: DraggerFileType): DraggerFileType => {
    return file;
  };

  const deleteMedia = (fileList: DraggerFileType[]): DraggerFileType[] => {
    return fileList;
  };

  // Modifications
  const addModification = (): void => { };

  const removeModification = (): void => { };

  const cloneModification = (): void => { };

  const saveModification = (): void => { };

  const PropertiesBlock = useCallback((): JSX.Element => {
    return (
      <div className="properties_wrapper mb-4">
        <p className="mx-2">Свойства</p>
        <div className="flex flex-col gap-2">
          <Form.List
            name="properties"
            rules={[{ validator: fillValidator }]}
          >
            {(fields, { add, remove }) => (
              <div className="properties_wrapper flex flex-col">
                {fields.map(({ key, name }) => (
                  <div className="property-row flex gap-2 mb-4" key={key}>
                    <Form.Item
                      name={[name, "propertyName"]}
                      rules={[{ required: true, message: "Введите название свойства" }]}
                      style={{ flex: 1, }}
                      className="m-0"
                    >
                      <Input size="large" placeholder="Введите название свойства" />
                    </Form.Item>
                    <Form.Item
                      name={[name, "propertyValue"]}
                      rules={[{ required: true, message: "Введите значение свойства" }]}
                      style={{ flex: 1, }}
                      className="m-0"
                    >
                      <Input size="large" placeholder="Введите значение свойства" />
                    </Form.Item>
                    <Button type="default" size="large" className="items-center text-indigo-600"
                      onClick={() => remove(name)}>
                      <TrashIcon />
                    </Button>
                  </div>
                ))}
                <div className="flex align-center">
                  <GhostButton
                    size="large"
                    onClickHandler={add}
                    text="+ Добавить свойство">
                  </GhostButton>
                </div>
              </div>
            )}
          </Form.List>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="importProductForm_wrapper">
      <Form.Item
        name="category"
        label="Категория"
        rules={[
          {
            required: true,
            message: "Это поле обязательно для заполнения",
          },
        ]}
        key="categoryField"
        className="mb-4"
      >
        <Cascader
          size="large"
          options={options}
          loadData={loadData}
          placeholder="Найти категорию"
          notFoundContent="Категория не найдена"
          showSearch
        // disabled={isDisabledCategory || !!products.list?.length || !!productCardId}
        />
      </Form.Item>
      <Form.Item
        name="name"
        label="Наименование"
        key="nameField"
        rules={[
          {
            required: true,
            message: "Это поле обязательно для заполнения",
          },
        ]}
        className="mb-4"
      >
        <Input size="large" placeholder="Введите наименование продукта" />
      </Form.Item>
      <Form.Item
        name="sku"
        label="Артикул"
        key="skuField"
        className="mb-4"
      >
        <Input size="large" placeholder="Введите артикул продукта" />
      </Form.Item>
      <Form.Item
        name="minOrderQuantity"
        label="Минимальное кол-во для заказа"
        key="minOrderQuantityField"
        className="mb-4"
      >
        <Input size="large" placeholder="Введите число" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Описание"
        key="descriptionField"
        className="mb-4"
      >
        <Input size="large" placeholder="Введите описание продукта" />
      </Form.Item>
      <div className="flex gap-2 items-end mb-4">
        <Form.Item
          name="price"
          label="Цена"
          key="priceField"
          className="mr-0 mb-0 w-full"
        >
          <Input size="large" placeholder="Введите цену" />
        </Form.Item>
        <Form.Item
          name="priceType"
          key="priceTypeField"
          className="ml-0 mb-0"
        >
          <Select
            size="large"
            style={{ maxWidth: 200 }}
            placeholder="Выберите валюту"
            options={[
              { value: "rub", label: "руб" },
              { value: "usd", label: "usd" },
              { value: "btc", label: "btc" },
            ]}
          />
        </Form.Item>
      </div>
      <PropertiesBlock />
      <PhotoBlock
        form={form}
        currentProduct={product}
        addMedia={addMedia}
        deleteMedia={deleteMedia}
        isDisabledFields={false}
      />
      <Form.Item name="showInCatalog">
        <Checkbox>Показать в каталоге</Checkbox>
      </Form.Item>
    </div>
  );
};

export default ImportProductForm;