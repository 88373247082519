import React, { FC, JSX, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";

// components
import MainLayout from "components/MainLayout/MainLayout";
import CatalogPage from "pages/CatalogPage";
import ImportMasterPage from "pages/ImportMasterPage";
import MatchingTableColumnsPage from "pages/MatchingTableColumnsPage";

const App: FC = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token: string = localStorage.getItem("authtoken");

    if (!token) {
      checkAndSetToken();
    }
  }, [location]);

  const saveTokenToLocalStorage = (token: string): void => {
    localStorage.setItem("authtoken", `"${token}"`);
  };

  const checkAndSetToken = (): void => {
    const currentUrl: string = window.location.href;
    const url: URL = new URL(currentUrl);
    const urlToken: string = url.searchParams.get("token");

    if (urlToken) {
      saveTokenToLocalStorage(urlToken);

      url.searchParams.delete("token");
      window.history.replaceState(null, "", url.toString());

      navigate(location.pathname);
    } else {
      window.location.href = `https://dev.www.smbconnect.ru/login?redirect=${currentUrl}`;
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/import" replace />} />
      <Route
        path="/import/:tab?"
        element={
          <MainLayout>
            <CatalogPage />
          </MainLayout>
        }
      />
      <Route path="/import-master/" element={<ImportMasterPage />} />
      <Route path="/import-master/excel/:taskId" element={<MatchingTableColumnsPage />} />
    </Routes>
  );
};

export default App;