import { FC } from "react";
import { useDispatch } from "react-redux";

import { createTempDocument } from "redux/thunks/tempDocuments.thunk";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Upload } from "antd";

import { AppDispatch } from "redux/store/store";
import { RcFile } from "antd/es/upload";
import { TemporaryDocumentType } from "tempDocuments/types";

interface IFileUploadButton {
  acceptType: string;
  description: string;
  setUploadedFileData?: React.Dispatch<React.SetStateAction<TemporaryDocumentType>>;
  maxFilesCount?: number;
};

const FileUploadButton: FC<IFileUploadButton> = ({
  acceptType,
  description,
  setUploadedFileData,
  maxFilesCount = 1,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleError = (messageText: string, error: any): void => {
    message.error(messageText);
    console.error(new Error(error));
  };

  const handleUploadFile = (file: RcFile): void => {
    dispatch(createTempDocument(file))
      .then((response: any) => setUploadedFileData?.(response))
      .catch((error: any) => handleError("Ошибка при загрузке документа", error));
  };

  const handleBeforeUpload = (file: RcFile, _fileList: RcFile[]): boolean => {
    handleUploadFile(file);

    return false;
  };

  return (
    <Form.Item className="m-0" name="fileField" valuePropName="filelist">
      <Upload
        beforeUpload={handleBeforeUpload}
        showUploadList
        className="flex flex-col"
        accept={acceptType}
        maxCount={maxFilesCount}
      >
        <div className="flex items-center gap-4">
          <Button
            type="primary"
            icon={<UploadOutlined />}
            className="align-center"
            size="middle"
            ghost
          >
            Выбрать файл
          </Button>
          <p className="ghost">{description}</p>
        </div>
      </Upload>
    </Form.Item>
  );
};

export default FileUploadButton;
