import React, { JSX, FC, ReactNode, forwardRef, Ref, RefAttributes } from "react";
import { Button, ButtonProps, Space } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface IPrimaryButtonProps extends ButtonProps {
  text?: string;
  icon?: ReactNode;
  onClickHandler?: () => void;
  size?: SizeType;
  htmlType?: "button" | "submit" | "reset";
  isLoading?: boolean;
  danger?: boolean;
  isDisabled?: boolean;
  className?: string;
}

const PrimaryButton: FC<IPrimaryButtonProps & RefAttributes<HTMLButtonElement>> = forwardRef(
  (props: IPrimaryButtonProps, ref: Ref<any>): JSX.Element => {
    const {
      icon,
      text,
      onClickHandler,
      isLoading = false,
      size = "large",
      htmlType = "button",
      danger = false,
      isDisabled = false,
      className = "",
    } = props;

    const loadingStyle = isLoading ? { backgroundColor: "#4096FF", color: "#fff" } : {};

    return (
      <Button
        ref={ref as any}
        type="primary"
        danger={danger}
        size={size}
        style={loadingStyle}
        htmlType={htmlType}
        disabled={isDisabled}
        className={`${className} disabled:border-none disabled:btn-disabled`}
        onClick={onClickHandler ? () => onClickHandler() : null}
      >
        <Space className="flex justify-center">
          {icon ?? ""}
          {text}
        </Space>
      </Button>
    );
  },
);

export default PrimaryButton;
