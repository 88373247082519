import { createSlice } from "@reduxjs/toolkit";
import { ImportTaskColumnType, ImportTaskType, PaginatedImportTasksType } from "importTask/types";

type DefautDataStateType = {
  error: string | null;
  isFetching: boolean;
};

export type TabModeType = "importTasks" | "sources";

export interface InitialImportTaskStateType extends DefautDataStateType {
  count: number;
  list: ImportTaskType[];
  selected: ImportTaskType;
  columns: ImportTaskColumnType;
  currentTable: TabModeType;
}

const initialState: InitialImportTaskStateType = {
  error: null,
  isFetching: false,
  count: 0,
  list: null,
  selected: null,
  columns: null,
  currentTable: "importTasks",
};

const importTaskSlice = createSlice({
  name: "importTask",
  initialState,
  reducers: {
    /** Переключение табов: "Автообновление" / "Разовые загрузки" */
    setCurrentTable(state, action: { payload: "importTasks" | "sources"; type: string }) {
      state.currentTable = action.payload;
    },
    /** Загрузка файла */
    createFileImportTaskRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    createFileImportTaskSuccess(state, action: { payload: ImportTaskType; type: string }) {
      state.error = null;
      state.isFetching = false;
      state.list = [action.payload, ...(state.list || [])];
      state.selected = action.payload;
    },
    createFileImportTaskFailure(state, _action) {
      state.error = null;
      state.isFetching = false;
    },

    /** Загрузка файла по ссылке */
    createSourceImportTaskRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    createSourceImportTaskSuccess(state, action: { payload: ImportTaskType; type: string }) {
      state.error = null;
      state.isFetching = false;
      state.list = [action.payload, ...(state.list || [])];
      state.selected = action.payload;
    },
    createSourceImportTaskFailure(state, _action) {
      state.error = null;
      state.isFetching = false;
    },

    /** Получение списка заданий на импорт из файла */
    fetchAllImportTasksRequest(state) {
      state.error = null;
      state.isFetching = true;
    },
    fetchAllImportTasksSuccess(state, action: { payload: PaginatedImportTasksType; type: string }) {
      state.error = null;
      state.isFetching = false;
      state.count = action.payload.total;
      state.list = action.payload.results;
    },
    fetchAllImportTasksFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },

    /** Получение задания на импорт из файла */
    fetchImportTaskRequest(state) {
      state.error = null;
      state.isFetching = true;
      state.selected = null;
    },
    fetchImportTaskSuccess(state, action: { payload: ImportTaskType; type: string }) {
      state.error = null;
      state.isFetching = false;
      state.selected = action.payload;
    },
    fetchImportTaskFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },

    /** Обновление задания на импорт из файла */
    updateImportTaskRequest(state) {
      state.error = null;
      state.isFetching = true;
    },
    updateImportTaskSuccess(state, action: { payload: ImportTaskType; type: string }) {
      state.list = [action.payload, ...(state.list?.filter((field) => field.id !== action.payload.id) || [])];
      state.error = null;
      state.isFetching = false;
    },
    updateImportTaskFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },

    /** Удаление задания */
    deleteImportTaskRequest(state, _action) {
      state.isFetching = true;
      state.error = null;
    },
    deleteImportTaskSuccess(state, action) {
      state.list = [...(state.list?.filter((field) => field.id !== action.payload) || [])];
      state.error = null;
      state.isFetching = false;
    },
    deleteImportTaskFailure(state, action) {
      state.isFetching = false;
      state.error = action.payload;
    },

    /** Очистка state */
    clearImportTaskStateSuccess(state) {
      state.list = null;
      state.selected = null;
    },

    /** Типы колонок для сопоставления */
    fetchConfigColumnsRequest(_state, _action) {},
    fetchConfigColumnsSuccess(state, action: { payload: ImportTaskColumnType; type: string }) {
      state.columns = action.payload;
    },
    fetchConfigColumnsFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = importTaskSlice;

export const {
  setCurrentTable,

  createFileImportTaskRequest,
  createFileImportTaskSuccess,
  createFileImportTaskFailure,

  createSourceImportTaskRequest,
  createSourceImportTaskSuccess,
  createSourceImportTaskFailure,

  fetchAllImportTasksRequest,
  fetchAllImportTasksSuccess,
  fetchAllImportTasksFailure,

  fetchImportTaskRequest,
  fetchImportTaskSuccess,
  fetchImportTaskFailure,

  updateImportTaskRequest,
  updateImportTaskSuccess,
  updateImportTaskFailure,

  deleteImportTaskRequest,
  deleteImportTaskSuccess,
  deleteImportTaskFailure,

  clearImportTaskStateSuccess,

  fetchConfigColumnsRequest,
  fetchConfigColumnsSuccess,
  fetchConfigColumnsFailure,
} = actions;

export default reducer;
