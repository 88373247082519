import { createSlice } from "@reduxjs/toolkit";
import {
  CategoryTPType,
  CategoryType,
} from "categories/types";

type DefautDataStateType = {
  error: string | null;
  isFetching: boolean;
};

export interface ICategories extends DefautDataStateType {
  count: number;
  list: CategoryType[];
}

export interface ICategoriesTP extends DefautDataStateType {
  list: CategoryTPType[];
}

export type InitialCategoriesStateType = {
  categories: ICategories;
  categoriesTP: ICategoriesTP;
};

const initialState: InitialCategoriesStateType = {
  categories: {
    count: 0,
    list: null,
    error: null,
    isFetching: false,
  },
  categoriesTP: {
    list: null,
    error: null,
    isFetching: false,
  },
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    /** Получение списка категорий поставщика
     * - GET - categories/all */
    fetchCategoriesAllRequest(state, _action) {
      state.categories.error = null;
      state.categories.isFetching = true;
    },
    fetchCategoriesAllSuccess(state, action) {
      state.categories.list = action.payload?.results;
      state.categories.count = action.payload?.total;
      state.categories.error = null;
      state.categories.isFetching = false;
    },
    fetchCategoriesAllFailure(state, action) {
      state.categories.list = null;
      state.categories.count = 0;
      state.categories.error = action.payload;
      state.categories.isFetching = false;
    },

    /** Получение списка категорий ТП 
     * - GET - categories/tp/all */
    fetchCategoriesTPAllRequest(state, _action) {
      state.categoriesTP.error = null;
      state.categoriesTP.isFetching = true;
    },
    fetchCategoriesTPAllSuccess(state, action) {
      state.categoriesTP.list = action.payload;
      state.categoriesTP.error = null;
      state.categoriesTP.isFetching = false;
    },
    fetchCategoriesTPAllFailure(state, action) {
      state.categoriesTP.list = null;
      state.categoriesTP.error = action.payload;
      state.categoriesTP.isFetching = false;
    },

    /** Сохранение сопоставления категорий поставщика и ТП
     * - POST categories/match/manual/ */
    saveCategoriesMatchRequest(state, _action) {
      state.categories.error = null;
      state.categories.isFetching = true;
    },
    saveCategoriesMatchSuccess(state, action) {
      state.categories.list = state.categories.list.map((category) => (
        category.id === action.payload.id
          ? { ...category, category: action.payload.category, category_name: action.payload.category_name }
          : category
      ));
    },
    saveCategoriesMatchFailure(state, action) {
      state.categories.error = action.payload;
      state.categories.isFetching = false;
    },

    /** Массовое удаление сопоставления категорий поставщика и ТП
     * - DELETE categories/match/ */
    deleteCategoriesMatchRequest(state, _action) {
      state.categories.error = null;
      state.categories.isFetching = true;
    },
    deleteCategoriesMatchSuccess(state, action) {
      state.categories.list = state.categories.list?.map((category) => (
        action.payload.includes(category.id) ? { ...category, category: null, category_name: null } : category
      ));
      state.categories.error = null;
      state.categories.isFetching = false;
    },
    deleteCategoriesMatchFailure(state, action) {
      state.categories.error = action.payload;
      state.categories.isFetching = false;
    },

    /** Массовое удаление категорий поставщика
     * - DELETE categories/ */
    deleteCategoriesRequest(state, _action) {
      state.categories.error = null;
      state.categories.isFetching = true;
    },
    deleteCategoriesSuccess(state) {
      state.categories.error = null;
      state.categories.isFetching = false;
    },
    deleteCategoriesFailure(state, action) {
      state.categories.error = action.payload;
      state.categories.isFetching = false;
    },

    /** Получение списка автоматически сопоставленных категорий
     * - GET categories/match/auto/ */
    fetchAutoCategoriesMatchRequest(state, _action) {
      state.categories.error = null;
      state.categories.isFetching = true;
    },
    fetchAutoCategoriesMatchSuccess(state, action) {
      state.categories.count = action.payload?.total;
      state.categories.list = action.payload?.results;
      state.categories.error = null;
      state.categories.isFetching = false;
    },
    fetchAutoCategoriesMatchFailure(state, action) {
      state.categories.error = action.payload;
      state.categories.isFetching = false;
    },
  },
});

const { actions, reducer } = categoriesSlice;

export const {
  fetchCategoriesAllRequest,
  fetchCategoriesAllSuccess,
  fetchCategoriesAllFailure,

  fetchCategoriesTPAllRequest,
  fetchCategoriesTPAllSuccess,
  fetchCategoriesTPAllFailure,

  saveCategoriesMatchRequest,
  saveCategoriesMatchSuccess,
  saveCategoriesMatchFailure,

  deleteCategoriesMatchRequest,
  deleteCategoriesMatchSuccess,
  deleteCategoriesMatchFailure,

  deleteCategoriesRequest,
  deleteCategoriesSuccess,
  deleteCategoriesFailure,

  fetchAutoCategoriesMatchRequest,
  fetchAutoCategoriesMatchSuccess,
  fetchAutoCategoriesMatchFailure,
} = actions;

export default reducer;
