import * as process from "process";
import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import {
  CategoryMatchRequestType,
  CategoryMatchType,
  CategoryType,
  PaginatedCategoryMatchType,
  PaginatedCategoryTPRequestType,
  PaginatedCategoryTPType,
  PaginatedCategoryType
} from "categories/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Категории ****/ }

/** Получение списка категорий */
export const getCategoriesAll = async (
  params: PaginatedCategoryType,
  cancelToken: CancelToken
): Promise<AxiosResponse<CategoryType[]>> => {
  return axios.GET(API_BASE, "/api/v1/categories/all/", params, cancelToken);
};

/** Получение списка категорий ТП */
export const getCategoriesTPAll = async (
  params: PaginatedCategoryTPRequestType,
  cancelToken: CancelToken,
): Promise<AxiosResponse<PaginatedCategoryTPType>> => {
  return axios.GET(API_BASE, "/api/v1/categories/tp/all/", params, cancelToken);
};

/** Сохранение сопоставления категорий поставщика и ТП */
export const saveCategoriesMatch = async (
  body: CategoryMatchRequestType,
  cancelToken: CancelToken,
): Promise<AxiosResponse<CategoryMatchType>> => {
  return axios.POST(API_BASE, "/api/v1/categories/match/manual/", body, cancelToken);
};

/** Массовое удаление сопоставления категорий поставщика и ТП */
export const deleteCategoriesMatch = async (
  ids: number[],
  cancelToken: CancelToken,
): Promise<AxiosResponse<any>> => { // Нет тела ответа
  return axios.DELETE(API_BASE, "/api/v1/categories/match/", { ids }, cancelToken);
};

/** Массовое удаление категорий поставщика */
export const deleteSupplierCategories = async (
  ids: number[],
  cancelToken: CancelToken,
): Promise<AxiosResponse<any>> => { // Нет тела ответа
  return axios.DELETE(API_BASE, "/api/v1/categories/", { ids }, cancelToken);
};

/** Получение списка автоматически сопоставленных категорий */
export const postCategoriesMatchAuto = async (
  ratio: number,
  cancelToken: CancelToken,
): Promise<AxiosResponse<PaginatedCategoryMatchType>> => {
  return axios.POST(API_BASE, "/api/v1/categories/match/auto/", { ratio }, cancelToken);
};
