import React, { JSX, FC, useRef, useEffect } from "react";
import { DraggerFileType } from "../DragAndDrop";

// icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";
import { ReactComponent as PlayCircleIcon } from "../../../../assets/icons/play_circle_icon.svg";

import css from "../style.module.css";

interface IVideoPreviewContainerProps {
  file: DraggerFileType;
  onClickHandler: (file: any) => void;
}

const VideoPreviewContainer: FC<IVideoPreviewContainerProps> = ({ file, onClickHandler }): JSX.Element => {
  const videoRef: React.MutableRefObject<any> = useRef(null);

  useEffect((): void => {
    const loadImageFromVideo = async (): Promise<() => void> => {
      const video = videoRef.current;

      video.src = file.url;

      const loadedMetadataHandler = (): void => {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const context: CanvasRenderingContext2D = canvas.getContext("2d");

        canvas.width = 155.33;
        canvas.height = 155.33;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
      };

      video.addEventListener("loadedmetadata", loadedMetadataHandler);

      return () => {
        video.removeEventListener("loadedmetadata", loadedMetadataHandler);
      };
    };

    loadImageFromVideo();
  }, [file.url]);

  return (
    <div className="relative object-cover w-full h-0 pb-[100%]">
      <video
        ref={videoRef}
        preload="metadata"
        muted
        playsInline
        controls={false}
        className="absolute object-cover inset-0 w-full h-full rounded-lg"
      />
      <DeleteIcon onClick={() => onClickHandler(file)} className={`absolute cursor-pointer z-10 ${css.deleteIcon}`} />
      <div className="absolute inset-0 flex items-center justify-center">
        <PlayCircleIcon />
      </div>
    </div>
  );
};

export default VideoPreviewContainer;
