import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, NavigateFunction, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// redux
import { AppDispatch } from "redux/store/store";
import { createFileImportTask, createSourceImportTask } from "redux/thunks/importTask.thunk";

// utils
import useCancelableDebounce from "utils/useCancelableDebounce";

// components
import { Spin, Select, Radio, message, Tooltip, Button, Form, RadioChangeEvent } from "antd";
import ImportModePanel, { ImportModeType } from "components/ImportMaster/ImportModePanel";
import PageWrapper from "components/ui/PageWrapper";
import PagePanel from "components/ui/Panel/PagePanel";
import PanelBlock from "components/ui/Panel/PanelBlock";

// types
import { getWarehouses } from "redux/thunks/warehouses.thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import {
  ImportTaskUploadSettingsType,
  ImportTaskFileRequestType,
  ImportTaskAuthorizationType,
  ImportTaskLinkRequestType
} from "importTask/types";
import { SETTINGS_TYPE } from "components/ImportMaster/ImportYML";
import { useWatch } from "antd/es/form/Form";
import ImportMasterLayout from "components/ImportMaster/ImportMasterLayout";
import { setCurrentTable } from "redux/slices/importTaskSlice";

export type ImportMasterFormType = {
  uploadMode: "url" | "file";
  warehouse: string;
  source: ImportModeType;
  settings: string[];
  fileField: any;
  fileLink: string;
  name: string;
  username: string;
  password: string;
}

interface IImportMasterPage { }

const ImportMasterPage: FC<IImportMasterPage> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const [form] = Form.useForm<ImportMasterFormType>();
  const { getFieldsValue, resetFields } = form;
  useWatch([], form);

  const {
    list: warehousesList,
    isFetching: isLoadingWarehouses
  } = useSelector((state: AppStateType) => state.warehousesSlice);
  const {
    item: uploadedFile
  } = useSelector((state: AppStateType) => state.tempDocumentsSlice);

  const [importMode, setImportMode] = useState<ImportModeType>("yml");
  const [selectedWarehouse, setSelectedWarehouse] = useState<number | null>(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true);

  const params: URLSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { debouncedFunction: fetchWarehouses } = useCancelableDebounce((_params, token) => {
    dispatch(getWarehouses(token))
      .catch((error) => {
        handleError("Ошибка при загрузке списка складов.", error);
      });
  });

  useEffect(() => {
    const mode: ImportModeType = params.get("mode") as ImportModeType;
    fetchWarehouses?.({});

    if (mode) setImportMode(mode);
  }, [params, fetchWarehouses]);

  useEffect(() => {
    resetFields(["fileField", "fileLink"]);
  }, [importMode]);

  useEffect(() => {
    const { fileField, fileLink } = getFieldsValue() || {};

    const hasUploadLink: boolean = !!fileLink;
    const hasFiles: boolean = !!fileField?.file?.name && !!uploadedFile?.original_filename;
    const hasSelectedWarehouse: boolean = !!selectedWarehouse;
    const isUploaded: boolean = hasFiles && fileField?.file?.name === uploadedFile?.original_filename;

    setIsUploadDisabled(!(isUploaded || hasUploadLink) || !hasSelectedWarehouse);
  }, [getFieldsValue(), uploadedFile, selectedWarehouse, importMode]);

  const onCancelHandler = (): void => navigate("/import");

  const handleError = (messageText: string, error: Error): void => {
    message.error(messageText);
    console.error(error?.message);
  };

  const handleChangeImportMode = (e: RadioChangeEvent): void => {
    setImportMode(e.target.value);
  };

  const handleSelectWarehouse = (value: number): void => {
    setSelectedWarehouse(value);
  };

  const getAuthSetting = (): ImportTaskAuthorizationType => {
    const { username, password } = getFieldsValue();

    return ({
      username,
      password,
    });
  };

  const getUploadSettings = (): ImportTaskUploadSettingsType => {
    const { settings, uploadMode } = getFieldsValue();
    const isYml: boolean = importMode === "yml";
    const isUrlMode: any = uploadMode === "url";

    return {
      is_periodic: isYml && isUrlMode && settings?.includes(SETTINGS_TYPE.isPeriodic),
      update_rest: isYml && settings?.includes(SETTINGS_TYPE.updateRest),
      update_price: isYml && settings?.includes(SETTINGS_TYPE.updatePrice),
      update_property: isYml && settings?.includes(SETTINGS_TYPE.updateProperty),
      clear_products: isYml && settings?.includes(SETTINGS_TYPE.clearProducts),
      first_line_skip: isYml && settings?.includes(SETTINGS_TYPE.firstLineSkip),
    };
  };

  const getRequestBody = (): ImportTaskFileRequestType | ImportTaskLinkRequestType => {
    const { name, fileLink, settings } = getFieldsValue();

    const requestBody: ImportTaskFileRequestType | ImportTaskLinkRequestType = {
      ...getUploadSettings(),
      warehouse_id: selectedWarehouse,
      name: name,
      temp_document_id: uploadedFile?.id,
      link: fileLink,
      ...(settings?.includes(SETTINGS_TYPE.logIn) && getAuthSetting())
    };

    return requestBody;
  };

  const ImportModeBlock = useCallback((): JSX.Element => (
    <PanelBlock title={{ content: "Выберите вариант импорта" }}>
      <Radio.Group value={importMode} onChange={handleChangeImportMode}>
        <Tooltip title="Добавьте продукты, используя ручное создание">
          <Radio.Button value="manual">Ручной импорт</Radio.Button>
        </Tooltip>
        <Tooltip title="Добавьте продукты, используя прейскурант в Excel (XLS, CSV)">
          <Radio.Button value="xls">Импорт excel</Radio.Button>
        </Tooltip>
        <Tooltip title="Публикация продуктов с использованием формата YML, Yandex.Market или Commerce-ML">
          <Radio.Button value="yml">Импорт YML</Radio.Button>
        </Tooltip>
      </Radio.Group>
    </PanelBlock>
  ), [importMode]);

  const WarehouseBlock = useCallback((): JSX.Element => importMode !== "manual" && (
    <PanelBlock title={{ content: "Выбор склада" }}>
      <Form.Item name="warehouse" className="w-full m-0">
        <Select
          placeholder="Список складов"
          value={selectedWarehouse}
          onSelect={handleSelectWarehouse}
          disabled={!warehousesList?.length}
          notFoundContent={isLoadingWarehouses ? <Spin spinning={isLoadingWarehouses} /> : null}
          className="w-full"
        >
          {warehousesList?.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </PanelBlock>
  ), [
    importMode,
    selectedWarehouse,
    warehousesList,
    isLoadingWarehouses,
  ]);

  const UploadPanel = useCallback((): JSX.Element => {
    const handleCreateTask = (): void => {
      const { fileLink } = getFieldsValue() || {};
      const hasUploadLink: boolean = !!fileLink;
      const isPeriodic: boolean = !!getUploadSettings()?.is_periodic;
      const requestBody: ImportTaskFileRequestType | ImportTaskLinkRequestType = getRequestBody();

      const createImportTaskThunkAction = importMode === "yml" && hasUploadLink
        ? createSourceImportTask(importMode, requestBody as ImportTaskLinkRequestType)
        : createFileImportTask(importMode, requestBody as ImportTaskFileRequestType);

      const showMessage = (type: "error" | "success", error?: any): void => {
        const successMessage: string = isPeriodic ? "Периодическая задача" : "Задача";
        const errorMessage: string = isPeriodic ? "периодической задачи" : "задачи";

        if (type === "success") {
          message.success(`${successMessage} успешно создана`);
        } else {
          handleError(`Ошибка при создании ${errorMessage}`, error);
        }
      };

      dispatch(createImportTaskThunkAction)
        .then(({ id: taskId, source, is_periodic }) => {
          const isYML: boolean = source === "YML";
          const path: string = isYML
            ? "/import/1"
            : `/import-master/excel/${taskId}`;

          dispatch(setCurrentTable(isYML && is_periodic ? "importTasks" : "sources"));

          showMessage("success");
          navigate(path);
        })
        .catch((error: any) => showMessage("error", error));
    };

    const ImportButton = (): JSX.Element => (
      <Tooltip
        title={isUploadDisabled && "Заполните все необходимые данные и обязательные поля помеченные звездочкой (*)"}
      >
        <Button
          type="primary"
          size="large"
          onClick={handleCreateTask}
          disabled={isUploadDisabled}
        >
          Загрузить каталог
        </Button>
      </Tooltip>
    );

    const ManualButtons = (): JSX.Element => (
      <div className="buttons_wrapper flex gap-2">
        <Tooltip>
          <Button
            size="large"
          >
            Сохранить как черновик
          </Button>
        </Tooltip>
        <Tooltip>
          <Button
            size="large"
            onClick={() => console.log({values: form.getFieldsValue()})}
          >
            Сохранить и завершить
          </Button>
        </Tooltip>
      </div>
    );

    return (
      <PagePanel className="apply_wrapper flex justify-end">
        {
          importMode === "manual"
            ? <ManualButtons />
            : <ImportButton />
        }
      </PagePanel>
    );
  }, [isUploadDisabled, importMode, getUploadSettings()]);

  return (
    <ImportMasterLayout title="Добавление каталога" cancelHandler={onCancelHandler}>
      <PageWrapper className="gap-0 p-2 py-0 sm:px-2 md:px-8 lg:px-16 xl:px-24 2xl:px-32">
        <Form form={form} layout="vertical">
          <PagePanel className="flex flex-col md:flex-row gap-4">
            <ImportModeBlock />
            <WarehouseBlock />
          </PagePanel>
          <ImportModePanel importMode={importMode} />
        </Form>
        <UploadPanel />
      </PageWrapper>
    </ImportMasterLayout>
  );
};

export default ImportMasterPage;