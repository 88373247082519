import * as CategoriesAPI from "api/categories";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import { AxiosResponse, CancelToken } from "axios";

import {
  CategoryMatchType,
  CategoryType,
  PaginatedCategoryMatchType,
  PaginatedCategoryTPType, PaginatedCategoryType
} from "categories/types";
import {
  deleteCategoriesFailure,
  deleteCategoriesMatchFailure,
  deleteCategoriesMatchRequest,
  deleteCategoriesMatchSuccess,
  deleteCategoriesRequest,
  deleteCategoriesSuccess,
  fetchAutoCategoriesMatchFailure,
  fetchAutoCategoriesMatchRequest,
  fetchAutoCategoriesMatchSuccess,
  fetchCategoriesAllFailure,
  fetchCategoriesAllRequest,
  fetchCategoriesAllSuccess,
  fetchCategoriesTPAllFailure,
  fetchCategoriesTPAllRequest,
  fetchCategoriesTPAllSuccess,
  saveCategoriesMatchFailure,
  saveCategoriesMatchRequest,
  saveCategoriesMatchSuccess,
} from "../slices/categoriesSlice";

/** Получение списка категорий поставщика */
export const fetchCategories = (
  params: PaginatedCategoryType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchCategoriesAllRequest({}));
  try {
    const response: AxiosResponse<CategoryType[], any> = await CategoriesAPI.getCategoriesAll(params, cancelToken);

    dispatch(fetchCategoriesAllSuccess(response?.data));

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(fetchCategoriesAllFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Получение списка категорий ТП */
export const fetchTPCategories = (
  search?: string,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchCategoriesTPAllRequest({}));
  try {
    const response: AxiosResponse<PaginatedCategoryTPType, any> = await CategoriesAPI.getCategoriesTPAll(
      { search },
      cancelToken
    );

    const formattedResponse = response?.data?.results?.map(({ id, name }) => (
      { value: id, label: name }
    ));

    dispatch(fetchCategoriesTPAllSuccess(formattedResponse));

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(fetchCategoriesTPAllFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Сохранение сопоставления категорий поставщика и ТП */
export const saveCategoriesMatch = (
  supplierId: number,
  tpId: number,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(saveCategoriesMatchRequest({}));
  try {
    const response: AxiosResponse<CategoryMatchType, any> = await CategoriesAPI.saveCategoriesMatch(
      { category_supplier_id: supplierId, category_tp_id: tpId },
      cancelToken
    );

    dispatch(saveCategoriesMatchSuccess(response?.data));

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(saveCategoriesMatchFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Получение списка автоматически сопоставленных категорий */
export const setCategoriesMatchAuto = (
  ratio: number,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchAutoCategoriesMatchRequest({}));
  try {
    const response: AxiosResponse<PaginatedCategoryMatchType, any> = await CategoriesAPI.postCategoriesMatchAuto(
      ratio,
      cancelToken
    );

    dispatch(fetchAutoCategoriesMatchSuccess(response?.data));

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(fetchAutoCategoriesMatchFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Массовое удаление сопоставления категорий поставщика и ТП */
export const deleteCategoriesMatch = (
  ids: number[],
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteCategoriesMatchRequest({}));
  try {
    const response: AxiosResponse<PaginatedCategoryMatchType, any> = await CategoriesAPI.deleteCategoriesMatch(
      ids,
      cancelToken
    );

    dispatch(deleteCategoriesMatchSuccess(ids));

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(deleteCategoriesMatchFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Массовое удаление категорий поставщика */
export const deleteSupplierCategories = (
  ids: number[],
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteCategoriesRequest({}));
  try {
    const response: AxiosResponse<PaginatedCategoryMatchType, any> = await CategoriesAPI.deleteSupplierCategories(
      ids,
      cancelToken
    );

    dispatch(deleteCategoriesSuccess());

    return Promise.resolve(response);
  } catch (error: any) {
    dispatch(deleteCategoriesFailure(error?.message));

    return Promise.reject(error);
  }
};
