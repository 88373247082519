import React, { FC, JSX, ReactNode } from "react";
import { Button, Modal, Spin } from "antd";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";

interface IConfirmModalProps {
  text: string;
  isOpen: boolean;
  onOkHandler: () => void;
  onCancelHandler: () => void;
  isApplyDisabled?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  text,
  isOpen,
  onOkHandler,
  onCancelHandler,
  children,
  isApplyDisabled = false,
  isLoading = false,
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      width={572}
      centered
      onCancel={onCancelHandler}
      styles={{
        mask: { backgroundColor: "rgba(16, 25, 40, 0.5)" }
      }}
    >
      <Spin spinning={isLoading}>
        <h5 className="main-color">Подтверждение действия</h5>
        <p className="secondary-color text-14-r my-5">{text}</p>
        {children}
        <div className="flex justify-end">
          <Button
            size="large"
            className="mr-2"
            onClick={onCancelHandler}
          >
            Отменить
          </Button>
          <PrimaryButton
            text="Подтвердить"
            onClickHandler={onOkHandler}
            isDisabled={isApplyDisabled}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ConfirmModal;
