import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";
import { ReactComponent as PaperClipIcon } from "assets/icons/paper-clip.svg";

import { ImportTaskType } from "importTask/types";

export const renderFileColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="File"
      title={renderColumnTitle("Файл")}
      dataIndex="file"
      width={300}
      render={(_value, entity: ImportTaskType) => (
        <div className="flex items-center">
          <PaperClipIcon className="mr-2" />
          <a
            href={entity?.file_link}
            target="_blank"
            rel="noreferrer"
            className="blue-color"
          >
            <TextWithTooltip text={entity?.filename}/>
          </a>
        </div>
      )}
    />
  );
};
