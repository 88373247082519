import * as process from "process";
import axios from "utils/axios";

import { RequestMyCatalogFiltersType, RequestPaginationType } from "app/types";
import { CancelToken } from "axios";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const getMyCatalog = async (
  params: RequestPaginationType | RequestMyCatalogFiltersType,
  cancelToken?: CancelToken
): Promise<any> => {
  return axios.GET(API_BASE, "/api/v1/product/all/", params, cancelToken);
};

export const getPriceTypeList = async (): Promise<any> => {
  return axios.GET(API_BASE, "/api/v1/price-type-list/");
};

export const getCategories = async (): Promise<any> => {
  return axios.GET(API_BASE, "/api/v1/categories/");
};

/** Массовое или единичное удаление товаров из Моего каталога */
export const deleteFromMyCatalog = async (ids: string[]): Promise<any> => {
  return axios.DELETE(API_BASE, "/api/v1/my-catalog/", {
    ids
  });
};
