const getSearchParams = (params: any): URLSearchParams => {
  const searchParams: URLSearchParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
    (Array.isArray(params[key]) ? params[key] : [params[key]]).forEach(value => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
  });

  return searchParams;
};

export default getSearchParams;
