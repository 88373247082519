import * as TempDocumentsAPI from "../../api/tempDocuments";
import { ThunkAction } from "redux-thunk";

import {
  createTempDocumentRequest,
  createTempDocumentSuccess,
  createTempDocumentFailure,

  fetchTempDocumentRequest,
  fetchTempDocumentSuccess,
  fetchTempDocumentFailure,
} from "../slices/tempDocumentsSlice";

import { AppStateType } from "../reducers/mainReducer";
import { AxiosResponse, CancelToken } from "axios";
import { TemporaryDocumentType } from "tempDocuments/types";
import { RcFile } from "antd/es/upload";

{/**** Временный документ ****/ }

/** Получение временного документа */
export const fetchTempDocument = (
  documentId: string,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<TemporaryDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchTempDocumentRequest(documentId));
  try {
    const response: AxiosResponse<TemporaryDocumentType, any> =
      await TempDocumentsAPI.getTempDocument(documentId, cancelToken);

    dispatch(fetchTempDocumentSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchTempDocumentFailure(error));

    return Promise.reject(error);
  }
};

/** Создание временного документа */
export const createTempDocument = (
  file: RcFile,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<TemporaryDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createTempDocumentRequest(file.name));
  try {
    const response: AxiosResponse<TemporaryDocumentType, any> =
      await TempDocumentsAPI.postTempDocument(file, cancelToken);

    dispatch(createTempDocumentSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createTempDocumentFailure(error));

    return Promise.reject(error);
  }
};
