const blueColor: string = "#1E4BD2";
const redColor: string = "#FF3B30";

export const customTheme = {
  // Глобальные токены для настройки основных цветов antd
  token: {
    colorError: redColor,
    colorPrimary: blueColor,
    colorLink: blueColor,
    colorInfo: blueColor,
    colorLinkHover: "rgba(30, 75, 210, 0.86)",
  },
  // Design токены для настройки цветов в отдельных компонентах
  components: {
    Layout: {
      bodyBg: "ffffff",
    },
    Menu: {
      itemBg: "#F4F5F7",
      itemSelectedBg: "transparent",
    },
    Table: {
      headerBg: "#F4F5F7",
      rowHoverBg: "#F4F5F7",
    },
  },
};
