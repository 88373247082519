import React, { JSX, FC, useState, useEffect } from "react";

import { useWatch } from "antd/es/form/Form";

import { Button, FormInstance, List, Skeleton } from "antd";
import ModificationCard from "components/Products/ModificationCard";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import ConfirmModal from "components/ui/modals/ConfirmModal/ConfirmModal";

import "./style.scss";

import { ProductType } from "products/types";

import mockList from "components/Products/mockList.json";

interface IProductModificationsWrapperProps {
  form: FormInstance;
  isLoading: boolean;
  modificationId: string;
  setModificationId: React.Dispatch<React.SetStateAction<string>>;
}

const ProductModificationsWrapper: FC<IProductModificationsWrapperProps> = ({
  form,
  modificationId,
  setModificationId,
}): JSX.Element => {
  const values = useWatch([], form);

  const [selectedModification, setSelectedModification] = useState<string>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const products: ProductType[] = mockList.products;
  const isAddButtonDisabled: boolean = !products
    || products?.length === 0
    || products?.length === 30;

  useEffect(() => {
    setSelectedModification(modificationId);
  }, [modificationId]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const onAddHandler = (): void => {
  };

  const onDeleteHandler = async (): Promise<void> => {
  };

  const resetProductFormExcludingCategory = (): void => {
    const category = values?.category;

    form.resetFields();
    form.setFieldsValue({ category });
  };

  return (
    <div className="modifications_wrapper h-full w-full">
      <div className="options_block">
        <List
          dataSource={products}
          renderItem={(product: ProductType) => (
            <Skeleton
              avatar={{ className: "skeleton-list-item" }}
              paragraph={{ rows: 1 }}
              className="px-4 py-3 cursor-pointer mb-3"
              style={{ border: "1px solid #1e4bd2", borderRadius: "12px" }}
              loading={isLoading}
              active={isLoading}
            >
              <ModificationCard
                key={product.id}
                product={product}
                setModificationId={setModificationId}
                selectedModification={selectedModification}
                setSelectedModification={setSelectedModification}
                resetForm={resetProductFormExcludingCategory}
              />
            </Skeleton>
          )}>

        </List>
      </div>

      <div className="actions_wrapper flex flex-col gap-2">
        <div className="flex justify-between gap-2">
          <PrimaryButton
            className="w-full"
            text="Добавить вариант"
            onClickHandler={onAddHandler}
            isDisabled={isAddButtonDisabled}
          />
        </div>
        <div className="flex gap-2">
          <Button
            size="large"
            className="w-full grow-1/2"
            disabled={!selectedModification}
          >
            Дублировать
          </Button>
          <Button
            size="large"
            className="w-full grow-1/2"
            onClick={() => setIsShowDeleteModal(true)}
            disabled={!selectedModification}>
            Удалить выбранный
          </Button>
        </div>
      </div>
      <ConfirmModal
        text="Модификация будет удалена."
        isOpen={!!isShowDeleteModal}
        onOkHandler={onDeleteHandler}
        onCancelHandler={() => setIsShowDeleteModal(false)}
      />
    </div>
  );
};

export default ProductModificationsWrapper;
