import React, { JSX, FC } from "react";
import { Divider, Modal } from "antd";

//icons
import { ReactComponent as FileIcon } from "../../../assets/icons/file-icon.svg";
import { ReactComponent as CropIcon } from "../../../assets/icons/crop.svg";
import { ReactComponent as GradientIcon } from "../../../assets/icons/gradient_icon.svg";
import { ReactComponent as PictureInPicture } from "../../../assets/icons/picture_in_picture_center.svg";

import GhostButton from "../../ui/GhostButton/GhostButton";

type RequirementType = {
  icon: JSX.Element;
  text: string;
  hint?: string;
};

interface IMediaRequirementsModalProps {
  isOpen: boolean;
  onCancelHandler: () => void;
}

const photoRequirements: RequirementType[] = [
  {
    icon: <FileIcon />,
    text: "Формат: JPG или PNG",
  },
  {
    icon: <PictureInPicture />,
    text: "Максимальный размер изображения до 10 МБ",
  },
  {
    icon: <CropIcon />,
    text: "Минимальное разрешение 700 x 900 px",
    hint: "Разрешение может быть не менее 700 пикселей по наименьшей стороне.",
  },
  {
    icon: <PictureInPicture />,
    text: "Соотношение сторон 3:4",
  },
  {
    icon: <GradientIcon />,
    text: "Качество сжатия не ниже 65%",
  },
];

const MediaRequirementsModal: FC<IMediaRequirementsModalProps> = ({ isOpen, onCancelHandler }): JSX.Element => {
  const renderRequirementsBlock = (title: string, requirements: RequirementType[]) => {
    return (
      <div>
        <p className="text-16-m mb-3">{title}</p>
        {requirements.map(({ icon, text, hint }: RequirementType, idx: number) => (
          <div key={idx} className="pr-14">
            <p className={`flex ${hint ? "" : "mb-2"}`}>
              {icon}
              <span className="ml-2">{text}</span>
            </p>
            {hint && (
              <p className="text-12-r mb-2 pl-6 secondary-color">{hint}</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      width={572}
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <h4 className="mb-5">Требования для загрузки медиафайлов</h4>
      {renderRequirementsBlock("Фото", photoRequirements)}
      <Divider />
      <div className="flex justify-end">
        <GhostButton text="Назад" size="large" onClickHandler={onCancelHandler} />
      </div>
    </Modal>
  );
};

export default MediaRequirementsModal;
