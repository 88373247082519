import * as process from "process";
import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import { WarehouseType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Склады ****/ }

/** Получение списка складов */
export const getWarehouses = async (
  cancelToken: CancelToken
): Promise<AxiosResponse<WarehouseType[]>> => {
  return axios.GET(API_BASE, "/api/v1/warehouses/all/", null, cancelToken);
};