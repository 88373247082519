import React, { JSX } from "react";
import { MyCatalogProductType } from "app/types";

// components
import ImageContainer from "components/ui/ImageContainer/ImageContainer";
import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

import css from "../style.module.css";

export const renderNameColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Name"
      title={renderColumnTitle("Наименование товара")}
      dataIndex="name"
      width={250}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word",
        }
      })}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(text: string, record: MyCatalogProductType) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {}} // TODO: здесь будет навигация на форму редактирования продукта
          >
            <ImageContainer
              src={record.photo}
              alt="Фото"
              imageContainerClass={css.photo}
            />
            <TextWithTooltip text={text} />
          </div>
        );
      }}
    />
  );
};
