import React, { FC, JSX, useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";

// types
import { DefaultOptionType } from "antd/es/select";
import { EditPropsType } from "../CatalogTable";
import { CategoryType } from "categories/types";

// redux
import { InitialCategoriesStateType } from "../../../../redux/slices/categoriesSlice";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers/mainReducer";

// components
import { Form, Typography } from "antd";
import SelectWithButton from "../../../ui/SelectWithButton/SelectWithButton";
import Column from "antd/es/table/Column";

export type ComparisonType = {
  idTPCategory: number;
  nameTPCategory: string;
};

interface IEditControlsProps {
  categoryId: number;
  comparison: ComparisonType;
  editOptions: EditPropsType;
}

const EditControls: FC<IEditControlsProps> = ({
  categoryId,
  comparison,
  editOptions,
}) => {
  const categories: InitialCategoriesStateType = useSelector(
    (state: AppStateType) => state.categoriesSlice
  );

  const { edit, save, editingKey, setEditingKey } = editOptions ?? {};
  const { idTPCategory, nameTPCategory } = comparison ?? {};

  const [form] = useForm<any>();

  const { getFieldValue, setFieldsValue } = form;

  const [options, setOptions] = useState<DefaultOptionType[]>(null);
  const [selectedValue, setSelectedValue] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (categories?.categoriesTP?.list) {
      setOptions(categories?.categoriesTP?.list);
      setIsLoading(false);
    }
  }, [categories?.categoriesTP?.list]);

  useEffect(() => {
    setSelectedValue(idTPCategory);
    setFieldsValue({
      tpCategory: {
        value: idTPCategory,
        label: nameTPCategory
      }});

    options?.length && idTPCategory && setOptions((prev) => prev?.some(({value}) => value === idTPCategory)
      ? prev
      : [...prev, { value: idTPCategory, label: nameTPCategory }]
    );
  }, [editingKey]);

  const handleDelete = (): void => setEditingKey("");

  const handleEdit = (): void => {
    !options?.length && setIsLoading(true);

    edit(categoryId);
  };

  const handleSelect = (value: number): void => setSelectedValue(value);

  const handleSave = (): void => save(selectedValue);

  return categoryId?.toString() === editingKey ? (
    <Form className="flex items-baseline gap-4 w-full">
      <SelectWithButton
        name="tpCategory"
        value={selectedValue}
        options={options}
        placeholder="Выберите категорию"
        onClickHandler={handleSave}
        onChangeHandler={handleSelect}
        wrapStyle={{width: "69%"}}
        selectStyle={{width: "85%"}}
        isDisabledBtn={isLoading || !selectedValue}
      />
      <Typography.Text
        className="blue-color cursor-pointer"
        onClick={handleDelete}
      >
        Отменить
      </Typography.Text>
    </Form>
  ) : (
    <p
      onClick={handleEdit}
      className="blue-color cursor-pointer"
    >
      Изменить
    </p>
  );
};

export const renderEditableBKCategoryColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  editOptions: EditPropsType
): JSX.Element => {
  const { editingKey } = editOptions ?? {};

  return (
    <Column
      key="Editable_bk_category"
      title={renderColumnTitle("Привязка к категории  Бизнес Коннект")}
      dataIndex="category_name"
      width={400}
      sorter={{multiple: 3}}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word"
        }
      })}
      render={(value: string, record: CategoryType) => {
        const renderEmptyCategory = (): JSX.Element => <span className="danger-color">Отсутствует</span>;

        return (
          <div className="flex items-center">
            {record?.id?.toString() !== editingKey && (
              <div className="mr-3">{value ?? renderEmptyCategory()}</div>
            )}
            <EditControls
              categoryId={record?.id}
              comparison={{ idTPCategory: record?.category, nameTPCategory: record?.category_name }}
              editOptions={editOptions}
            />
          </div>
        );
      }}
    />
  );
};
