import React, { JSX, FC, Dispatch, ReactNode, Key, useState, useEffect } from "react";
import axios from "axios";
import useCancelableDebounce from "../../../utils/useCancelableDebounce";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import {
  deleteCategoriesMatch,
  deleteSupplierCategories,
  fetchCategories,
  fetchTPCategories,
  saveCategoriesMatch,
  setCategoriesMatchAuto,
} from "../../../redux/thunks/categories.thunk";

// types
import { TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";
import { ICategories, ICategoriesTP } from "../../../redux/slices/categoriesSlice";
import { CategoryType, PaginatedCategoryType } from "categories/types";

// components
import InfoCard from "components/ui/InfoCard/InfoCard";
import SearchFilterPanel from "../SearchFilterPanel/SearchFilterPanel";
import CatalogTable from "../CatalogTable/CatalogTable";
import ProductsTableStub from "../ProductsTableStub/ProductsTableStub";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import { Dropdown, MenuProps, notification } from "antd";
import ConfirmModal from "../../ui/modals/ConfirmModal/ConfirmModal";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";

interface IComparisonOfCategoriesTabProps {
  setActiveTab: Dispatch<React.SetStateAction<string>>;
}

const ComparisonOfCategoriesTab: FC<IComparisonOfCategoriesTabProps> = ({setActiveTab}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const categories: ICategories = useSelector((state: AppStateType) => state.categoriesSlice).categories;
  const categoriesTP: ICategoriesTP = useSelector((state: AppStateType) => state.categoriesSlice).categoriesTP;

  const [searchParam, setSearchParam] = useState<string>(null);
  const [editingKey, setEditingKey] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<CategoryType[]>(null);
  const [categoriesToDelete, setCategoriesToDelete, ] = useState<CategoryType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [isShowInfoCard, setIsShowInfoCard] = useState<boolean>(localStorage.getItem("comparisonInfo") !== "true");
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const isEditing = (categoryId: number): boolean => categoryId?.toString() === editingKey;

  useEffect(() => {
    fetchCategoriesWithComparisons({});
    setSelectedRows(null);
  }, [paginationInfo]);

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value: CategoryType) => value.key));
  }, [selectedRows]);

  const handleError = (messageText: string, error: Error): void => {
    notification.error({
      message: "Произошла ошибка",
      description: messageText
    });
    console.error(error?.message);
  };

  const { debouncedFunction: fetchCategoriesWithComparisons } = useCancelableDebounce((_params, token) => {
    setIsLoading(true);

    dispatch(fetchCategories(getRequestPagination(), token))
      .catch((error: any) => !axios.isCancel(error) && handleError("Ошибка при загрузке списка категорий.", error))
      .finally(() => setIsLoading(false));
  });

  const getRequestPagination = (): PaginatedCategoryType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
  });

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  const getTPCategories = (search: string): void => {
    dispatch(fetchTPCategories(search))
      .catch((error: any) => {
        !axios.isCancel(error) && handleError("Ошибка при загрузке списка категорий Торговой площадки.", error);
      });
  };

  const edit = (categoryId: string): void => {
    setEditingKey(categoryId?.toString());

    !categoriesTP?.list?.length && getTPCategories(searchParam);
  };

  const matchAutomatically = (): void => {
    setIsLoading(true);

    dispatch(setCategoriesMatchAuto(0.55))
      .then(() => notification.success({ message: "Категории успешно сопоставлены" }))
      .catch((error) => handleError(
        "При автоматическом сопоставлении категорий произошла ошибка, пожалуйста, повторите позже.",
        error
      ))
      .finally(() => setIsLoading(false));
  };

  const saveComparison = (tpCategory: number): void => {
    setIsLoading(true);

    dispatch(saveCategoriesMatch(Number(editingKey), tpCategory))
      .then(() => {
        notification.success({ message: "Категория успешно привязана" });
        setEditingKey("");
      })
      .catch((error) => handleError(
        "При привязке к категории Бизнес Коннект произошла ошибка, пожалуйста, повторите позже.",
        error
      ))
      .finally(() => setIsLoading(false));
  };

  const deleteComparisons = (values: CategoryType[]): void => {
    setIsLoading(true);

    const ids: number[] = values
      ?.filter(({ category }) => Boolean(category))
      ?.map(({ id }) => id);

    dispatch(deleteCategoriesMatch(ids))
      .then(() => {
        notification.success({message: "Привязки успешно удалены"});
        setSelectedRows(null);
      })
      .catch((error) => handleError("При удалении произошла ошибка, пожалуйста, повторите позже.", error))
      .finally(() => setIsLoading(false));
  };

  const deleteCategories = (): void => {
    setIsLoading(true);

    const ids: number[] = categoriesToDelete?.map(({ id }) => id);

    dispatch(deleteSupplierCategories(ids))
      .then(() => {
        notification.success({message: "Категории успешно удалены"});
        setSelectedRows(null);
      })
      .then(() => fetchCategoriesWithComparisons({ search: searchParam }))
      .catch((error) => handleError("При удалении категорий произошла ошибка, пожалуйста, повторите позже.", error))
      .finally(() => setIsLoading(false));
  };

  const showDeleteModal = (values: CategoryType[]): void => {
    setCategoriesToDelete(values);
    setIsShowDeleteModal(true);
  };

  const hideDeleteModal = (): void => setIsShowDeleteModal(false);

  const renderControls = (): JSX.Element => {
    return (
      <div className="flex justify-end mb-3">
        {/*TODO тут будет 2 фильтра на наличие привязки*/}
        <PrimaryButton
          text="Авто. сопоставление категорий"
          size="middle"
          onClickHandler={matchAutomatically}
        />
      </div>
    );
  };

  const renderActions = (value: CategoryType, idx: number): ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: <a style={{color: "#FF4D4F"}}>Удалить привязку <br/> к категории портала</a>,
        key: `delete-comparison-${idx}`,
        disabled: !value.category,
        onClick: () => deleteComparisons([value]),
      },
      {
        label: <a style={{color: "#FF4D4F"}}>Удалить категорию</a>,
        key: `delete-${idx}`,
        onClick: () => showDeleteModal([value]),
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const hideInfoCard = (): void => {
    localStorage.setItem("comparisonInfo", "true");
    setIsShowInfoCard(false);
  };

  const selectedRowsWithComparisonsCount: number = selectedRows
    ?.filter(({ category }) => Boolean(category))?.length;

  return (
    <div>
      {isShowInfoCard && (
        <InfoCard
          text={{
            content: `Сопоставление категорий поставщика с категориями портала Бизнес Коннект необходимо для того,
              чтобы товары поставщика отображались в соответствующим им разделам каталога.`
          }}
          actionBtn={<CloseIcon onClick={hideInfoCard} className="cursor-pointer" />}
          icon={{ type: "question" }}
        />
      )}
      <div className="flex">
        <div className="flex">
          {!!selectedRows?.length && (
            <PrimaryButton
              className="mr-3"
              text={`Удалить (${selectedRows?.length})`}
              danger
              onClickHandler={() => showDeleteModal(selectedRows)}
            />
          )}
          {!!selectedRowsWithComparisonsCount && (
            <PrimaryButton
              className="mr-3"
              danger
              text={`Удалить привязку (${selectedRowsWithComparisonsCount})`}
              onClickHandler={() => deleteComparisons(selectedRows)}
            />
          )}
        </div>
        <SearchFilterPanel
          placeholder="Найти по наименованию категории"
          onSearch={(value) => console.log(value)}
        />
      </div>
      {renderControls()}
      <CatalogTable
        columns={[
          "category_supplier",
          "editable_bk_category",
          "products_count",
          "actions"
        ]}
        list={categories?.list}
        count={categories?.count}
        isFetching={isLoading}
        isShowEmptyBlock
        rowSelection={rowSelection}
        emptyBlock={<ProductsTableStub setActiveTab={setActiveTab}/>}
        renderActions={renderActions}
        setPaginationInfo={setPaginationInfo}
        editProps={{
          isLoading,
          setEditingKey,
          editingKey,
          setIsEditing: isEditing,
          save: saveComparison,
          edit
        }}
      />
      {isShowDeleteModal && (
        <ConfirmModal
          text="Вы уверены, что хотите удалить выбранные вами категории? Это действие необратимо."
          isOpen={isShowDeleteModal}
          onOkHandler={deleteCategories}
          onCancelHandler={hideDeleteModal}
        >
          <div className="mb-5">
            <p className="secondary-color">Выбранные категории</p>
            {categoriesToDelete?.length && categoriesToDelete.map(({ chain, id }) => (
              <p key={id}>{chain}</p>
            ))}
          </div>
        </ConfirmModal>
      )}
    </div>
  );
};

export default ComparisonOfCategoriesTab;
