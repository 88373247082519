import { createSlice } from "@reduxjs/toolkit";
import { WarehouseType } from "app/types";

export type InitialWarehousesStateType = {
  list: WarehouseType[];
  error: string | null;
  isFetching: boolean;
};

const initialState: InitialWarehousesStateType = {
  list: [],
  error: null,
  isFetching: false,
};

const warehousesSlice = createSlice({
  name: "warehouses",
  initialState,
  reducers: {
    /** Получение списка складов */
    fetchWarehousesRequest(state, _action) {
      state.list = [];
      state.error = null;
      state.isFetching = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.list = action.payload;
      state.error = null;
      state.isFetching = false;
    },
    fetchWarehousesFailure(state, action) {
      state.list = [];
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = warehousesSlice;

export const {
  fetchWarehousesRequest,
  fetchWarehousesSuccess,
  fetchWarehousesFailure,
} = actions;

export default reducer;
