import React, { FC, useEffect, useState } from "react";

import { ImportMasterFormType } from "pages/ImportMasterPage";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useWatch } from "antd/es/form/Form";
import PanelBlock from "components/ui/Panel/PanelBlock";
import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";
import ImportProductForm from "components/ImportMaster/ImportManual/ImportProductForm";

import css from "./style.module.scss";
import ProductModificationsWrapper from "components/Products/ProductModificationsWrapper";

import mockList from "components/Products/mockList.json";
import { ProductType } from "products/types";

interface IImportManualProps {
  initialSettings?: any;
}

const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
  title: { content: "Ручной импорт" },
  className: "flex flex-col md:flex-row gap-8"
};

const ImportManual: FC<IImportManualProps> = () => {
  const form = useFormInstance<ImportMasterFormType>();
  const { getFieldsValue } = form;
  const values = useWatch([], form);

  const [modificationId, setModificationId] = useState<string>(null);

  const products: ProductType[] = mockList.products;

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <PanelBlock
        title={{
          content: (
            <div className="flex justify-between">
              <p>Варианты товара</p>
              <p>{products?.length}/30</p>
            </div>
          )
        }}
        wrapper={{ className: "flex basis-1/4" }}
      >
        <ProductModificationsWrapper
          form={form}
          modificationId={modificationId}
          setModificationId={setModificationId}
          isLoading={false}
        />
      </PanelBlock>
      <PanelBlock wrapper={{ className: "flex basis-3/4" }}>
        <ImportProductForm product={null} />
      </PanelBlock>
    </PanelLayout>
  );
};

export default ImportManual;