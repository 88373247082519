import React, { JSX } from "react";
import { MyCatalogProductType } from "app/types";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

export const renderPriceColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Price"
      title={renderColumnTitle("Цена")}
      dataIndex="min_price"
      width={160}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: number, entity: MyCatalogProductType) =>
        <TextWithTooltip text={`${value} ${entity?.currency}`} />
      }
    />
  );
};
