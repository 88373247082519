import React, { JSX, FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";

// redux
import { AppDispatch } from "redux/store/store";
import { AppStateType } from "redux/reducers/mainReducer";
import { deleteImportTask, fetchAllImportTasks } from "redux/thunks/importTask.thunk";
import {
  fetchAllImportTasksSuccess,
  InitialImportTaskStateType,
  setCurrentTable,
  TabModeType
} from "redux/slices/importTaskSlice";
import { APP_PATHES } from "constants/appPathes";

// utils
import generateSortString from "utils/generateSortString";
import useCancelableDebounce from "utils/useCancelableDebounce";

// components
import { Dropdown, FormInstance, MenuProps, message, Segmented } from "antd";
import CatalogTable, { CatalogColumnType } from "components/Catalog/CatalogTable/CatalogTable";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import InfoCard from "components/ui/InfoCard/InfoCard";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";
import { ReactComponent as LoupeIcon } from "assets/icons/search-square-icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";

// types
import { ImportTaskType, PaginatedImportTasksRequestType } from "importTask/types";
import { SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { RequestPaginationType } from "app/types";
import axios from "axios";

interface IUploadingProductsTabProps {
  form: FormInstance;
}

const importTasksColumns: CatalogColumnType[] = [
  "created_at", "updated_at", "periodicity",
  "status", "source", "file", "actions"
];
const sourcesColumns: CatalogColumnType[] = ["date_time", "catalogue_type", "status", "source", "file", "actions"];

const UploadingProductsTab: FC<IUploadingProductsTabProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const importTask: InitialImportTaskStateType = useSelector((state: AppStateType) => state.importTaskSlice);

  const [isShowInfoCard, setIsShowInfoCard] = useState<boolean>(
    localStorage.getItem("uploadingProductsInfo") !== "true"
  );
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  // TODO: реализовать логику параметров запроса и работать с одним стейтом
  const [searchParams, setSearchParams] = useState<PaginatedImportTasksRequestType>({
  });
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });

  useEffect(() => {
    fetchImportTasks({
      ...getRequestPagination(),
      is_periodic: importTask?.currentTable === "importTasks",
    });
  }, [paginationInfo]);

  const { debouncedFunction: fetchImportTasks } = useCancelableDebounce((params, token) => {
    const searchParams: PaginatedImportTasksRequestType = {
      ...getRequestPagination(),
      is_periodic: true,
    };
    
    dispatch(fetchAllImportTasks(params || searchParams, token))
    .catch((error: any) => {
      if (!axios.isCancel(error)) {
        handleError("Ошибка при загрузке списка задач.", error);
      }
    });
  });

  const handleError = (messageText: string, error: Error): void => {
    message.error(messageText);
    console.error(error?.message);
  };

  const getRequestPagination = (): RequestPaginationType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
    ...(sorterInfo ? { order_by: generateSortString(sorterInfo) } : {}),
  });

  const renderEmptyBlock = (): JSX.Element => {
    const handleOnClick = (): void => navigate(APP_PATHES.IMPORT_MASTER);

    return (
      <EmptyBlock
        icon={<LoupeIcon className="mb-4" />}
        // style={{ height: "calc(100vh - 23rem)" }} // TODO: Адаптивная верстка
        title="Задач нет"
        description={<>Ранее вы не загружали товары, загрузите их сейчас</>}
        btn={<PrimaryButton onClickHandler={handleOnClick} text="Загрузить товары" />}
      />
    );
  };

  const handlerDeleteImportTask = (taskId: string): void => {
    dispatch(deleteImportTask({ import_task_id: taskId }))
      .then(() => message.success("Задача успешно удалена"))
      .catch(() => message.error("Ошибка при удалении задачи"));
  };

  const renderUploadAutoActions = (entity: ImportTaskType, idx: number): React.ReactNode => {
    const { id, status, source } = entity;

    const isShowPause: boolean = status !== "Остановлено"
      && status !== "Успешно"
      && status !== "Ошибка";

    const items: MenuProps["items"] = [
      {
        label: <a>Редактировать</a>,
        onClick: () => handleEdit(id, source),
        key: `edit-${idx}`,
      },
      importTask?.currentTable === "importTasks" && {
        label: <a onClick={() => { }}>{isShowPause ? "Остановить" : "Запустить"}</a>,
        key: `pause-${idx}`,
        disabled: true
      },
      {
        label: <a style={{ color: "#FF4D4F" }}>Удалить</a>,
        onClick: () => handlerDeleteImportTask(id),
        key: `delete-${idx}`,
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const handleEdit = (id: string, source: string): void => {
    if (source === "YML") {
      navigate(`/import`);
    } else {
      navigate(`/import-master/excel/${id}`);
      dispatch(fetchAllImportTasksSuccess({ results: null, total: 0, page: 1, page_size: 10 }));
    }
  };

  const renderTable = (): JSX.Element => {
    return (
      <CatalogTable
        columns={importTask?.currentTable === "importTasks" ? importTasksColumns : sourcesColumns}
        list={importTask.list}
        count={importTask.count}
        isFetching={importTask?.isFetching}
        isShowEmptyBlock
        emptyBlock={renderEmptyBlock()}
        renderActions={renderUploadAutoActions}
        setPaginationInfo={setPaginationInfo}
        onClickHandler={handleEdit}
      />
    );
  };

  const handleTabChange = (value: TabModeType): void => {
    fetchImportTasks({
      ...getRequestPagination(),
      is_periodic: value === "importTasks",
    });

    dispatch(setCurrentTable(value));
  };

  const hideInfoCard = (): void => {
    localStorage.setItem("uploadingProductsInfo", "true");
    setIsShowInfoCard(false);
  };

  return (
    <div>
      {isShowInfoCard && (
        <InfoCard
          text={{
            content: `Вы можете настроить автоматическое обновление каталога ваших товаров, цен и остатков.
              Для этого необходимо загрузить ссылку на YML каталог и выбрать опцию переодичной загрузки.`
          }}
          actionBtn={<CloseIcon onClick={hideInfoCard} className="cursor-pointer" />}
          icon={{ type: "exclamation" }}
        />
      )}
      <Segmented
        options={[
          { label: "Автообновление", value: "importTasks" },
          { label: "Разовые загрузки", value: "sources" },
        ]}
        onChange={handleTabChange}
        value={importTask?.currentTable}
      />
      {renderTable()}
    </div>
  );
};

export default UploadingProductsTab;
