import React, { FC, useState } from "react";
import { ReactComponent as NoPictureIcon } from "assets/icons/no-photo-icon.svg";

import css from "./style.module.css";

interface ImageContainerProps {
  src: string;
  alt: string;
  imageClass?: string;
  noImageClass?: string;
  imageContainerClass?: string;
  onClick?: () => void;
}

const ImageContainer: FC<ImageContainerProps> = ({
  src,
  alt,
  imageClass,
  noImageClass,
  imageContainerClass,
  onClick,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const noImageClassName: string = typeof (noImageClass) === "string" ? noImageClass : css.noImage;
  const imageContainerClassName: string = typeof (imageContainerClass) === "string"
    ? imageContainerClass
    : css.imageContainer;

  const handleError = (): void => setHasError(true);

  return (
    <div className={imageContainerClassName} onClick={onClick}>
      {src && !hasError ? (
        <img src={src} alt={alt} onError={handleError} className={imageClass ?? ""} />
      ) : (
        <NoPictureIcon
          width="100%"
          height="100%"
          className={noImageClassName}
        />
      )}
    </div>
  );
};

export default ImageContainer;
