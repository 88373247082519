import { ThunkAction } from "redux-thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import {
  createFileImportTaskRequest,
  createFileImportTaskSuccess,
  createFileImportTaskFailure,

  createSourceImportTaskRequest,
  createSourceImportTaskSuccess,
  createSourceImportTaskFailure,

  fetchAllImportTasksRequest,
  fetchAllImportTasksSuccess,
  fetchAllImportTasksFailure,

  fetchImportTaskRequest,
  fetchImportTaskSuccess,
  fetchImportTaskFailure,

  updateImportTaskRequest,
  updateImportTaskSuccess,
  updateImportTaskFailure,

  deleteImportTaskRequest,
  deleteImportTaskSuccess,
  deleteImportTaskFailure,

  clearImportTaskStateSuccess,

  fetchConfigColumnsRequest,
  fetchConfigColumnsSuccess,
  fetchConfigColumnsFailure,
} from "redux/slices/importTaskSlice";

import * as ImportTaskAPI from "api/importTask";

import {
  PaginatedImportTasksRequestType,
  FieldType,
  ImportTaskFileRequestType,
  ImportTaskType,
  ImportTaskRequestType,
  PaginatedImportTasksType,
  ImportTaskLinkRequestType,
  ImportTaskUpdateRequestType,
  ImportTaskColumnType
} from "importTask/types";
import { AxiosResponse, CancelToken } from "axios";

// TODO: Доработать логику создания (manual)
export const createFileImportTask = (
  mode: "xls" | "yml" | "manual",
  params: ImportTaskFileRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<ImportTaskType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createFileImportTaskRequest(params));
  try {
    const isXls: boolean = mode === "xls";

    const actionMethod = isXls
      ? ImportTaskAPI.postImportTaskXLSFile
      : ImportTaskAPI.postImportTaskYMLFile;

    const response: AxiosResponse<ImportTaskType, any> = await actionMethod(params, cancelToken);

    dispatch(createFileImportTaskSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createFileImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

export const createSourceImportTask = (
  mode: "xls" | "yml",
  params: ImportTaskLinkRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<ImportTaskType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createSourceImportTaskRequest(params));
  try {
    const isXls: boolean = mode === "xls";

    const actionMethod = isXls
      ? ImportTaskAPI.postImportTaskXLSSource
      : ImportTaskAPI.postImportTaskYMLSource;

    const response: AxiosResponse<ImportTaskType, any> = await actionMethod(params, cancelToken);

    dispatch(createSourceImportTaskSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createSourceImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

/** Получение списка колонок excel для сопоставления */
export const fetchConfigColumns = (): ThunkAction<
  Promise<ImportTaskColumnType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchConfigColumnsRequest({}));

  try {
    const response: AxiosResponse<ImportTaskColumnType> = await ImportTaskAPI.getXLSColumns();

    dispatch(fetchConfigColumnsSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchConfigColumnsFailure(error));

    return Promise.reject(error);
  }
};

/** Получение списка задач на импорт из файла */
export const fetchAllImportTasks = (
  params: PaginatedImportTasksRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<PaginatedImportTasksType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchAllImportTasksRequest());
  try {
    const response = await ImportTaskAPI.getAllImportTasks(params, cancelToken);

    dispatch(fetchAllImportTasksSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchAllImportTasksFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

/** Получение задачи на импорт */
export const fetchImportTask = (
  params: ImportTaskRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<ImportTaskType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchImportTaskRequest());
  try {
    const response = await ImportTaskAPI.getImportTask(params, cancelToken);

    dispatch(fetchImportTaskSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

/** Получение задачи на импорт */
export const updateImportTask = (
  params: ImportTaskRequestType,
  body: ImportTaskUpdateRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<ImportTaskType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(updateImportTaskRequest());
  try {
    const response: any = await ImportTaskAPI.updateImportTask(params, body, cancelToken);

    dispatch(updateImportTaskSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(updateImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};

/** Удаление задания */
export const deleteImportTask = (params: ImportTaskRequestType): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteImportTaskRequest(null));
  try {
    const response = await ImportTaskAPI.deleteImportTask(params);

    dispatch(deleteImportTaskSuccess(params.import_task_id));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(deleteImportTaskFailure(error?.response?.data?.error));

    return Promise.reject(error);
  }
};
