import React, { JSX, FC } from "react";
import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";
import { ReactComponent as NotificationsIcon } from "assets/icons/notifications-icon.svg";
import { ReactComponent as CartIcon } from "assets/icons/cart-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/icons/messages-icon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet-icon.svg";
import { Badge } from "antd";
import { Link } from "react-router-dom";

import css from "./style.module.css";

interface IHeaderNavBarProps {
  isVendor: boolean;
}

const HeaderNavBar: FC<IHeaderNavBarProps> = ({isVendor}): JSX.Element => {
  // TODO: когда появятся данные, передавать их в count
  const renderBadge = (icon: JSX.Element, count: number = 0): JSX.Element => {
    return (
      <Badge
        count={count || null}
        size="small"
        style={{ top: 5, right: 5 }}
      >
        {icon}
      </Badge>
    );
  };

  return (
    <div className={`flex ${css.navBarBlock}`}>
      {!isVendor && (
        <Link to="/help/main_ru">
          <HelpIcon className={`cursor-pointer ${css.icon}`} />
        </Link>
      )}
      {renderBadge(<NotificationsIcon className={`ml-4 cursor-pointer ${css.icon}`} />)}
      {!isVendor && (
        <>
          {renderBadge(
            <Link to={isVendor ? "/platform/cart" : "/new/platform/cart"}>
              <CartIcon className={`ml-4 cursor-pointer ${css.icon}`} />
            </Link>
          )}
          {renderBadge(
            <Link to="/chat">
              <MessagesIcon className={`ml-4 cursor-pointer ${css.icon}`} />
            </Link>
          )}
          <Link to="/wallet">
            <WalletIcon className={`mx-4 cursor-pointer ${css.icon}`} />
          </Link>
        </>
      )}
    </div>
  );
};

export default HeaderNavBar;
