import React, { FC, useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";

// components
import { Checkbox, Form, FormInstance, Select, Table } from "antd";
import Column from "antd/es/table/Column";
import PanelBlock from "../../ui/Panel/PanelBlock";
import { DefaultOptionType } from "antd/lib/select";
import { FieldType } from "importTask/types";

interface IMatchingColumnProps {
  form: FormInstance<any>;
  field: FieldType;
  index: number;
  selectOptions: DefaultOptionType[];
}

const MatchingColumn: FC<IMatchingColumnProps> = ({
  form,
  field,
  index,
  selectOptions
}) => {
  const { type, name, value } = field ?? {};

  const [selectedValue, setSelectedValue] = useState<DefaultOptionType>(null);

  useEffect(() => {
    type && setSelectedValue(selectOptions.find(({ value }) => value === type));

    form.setFieldsValue({ [`columnType-${index}`]: type });
  }, [type]);

  const data: Record<string, string>[] = value
    ?.filter((item: string) => Boolean(item))
    ?.map((value: string, idx: number) => {
      return ({ key: idx.toString(), name: value });
    });

  const handleSelect = (option: DefaultOptionType): void => {
    setSelectedValue(option);
  };

  return (
    <div className="flex justify-between flex-1 mb-8">
      <Table<Record<string, string>> dataSource={data} pagination={false} className="mr-4 basis-2/4">
        <Column title={name} dataIndex="name" />
      </Table>
      <PanelBlock
        title={{ content: "Тип колонки" }}
        wrapper={{ className: "basis-2/4" }}
      >
        {selectOptions && (
          <Form.Item name={`columnType-${index}`} className="mb-0">
            <Select
              key={`SelectColumnType-${index}`}
              value={selectedValue}
              options={selectOptions}
              onChange={(_value, option) => handleSelect(option)}
              allowClear
              labelInValue
            />
          </Form.Item>
        )}
      </PanelBlock>
    </div>
  );
};

export default MatchingColumn;
