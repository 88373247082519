import React, { JSX, FC, useState, Key, useEffect } from "react";
import axios from "axios";
import useCancelableDebounce from "utils/useCancelableDebounce";
import generateSortString from "utils/generateSortString";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { InitialMyCatalogStateType } from "redux/slices/myCatalogSlice";
import { AppDispatch } from "redux/store/store";
import { fetchMyCatalogList } from "redux/thunks/myCatalog.thunk";

//components
import { Dropdown, FormInstance, MenuProps, message, Select } from "antd";
import CatalogTable from "components/Catalog/CatalogTable/CatalogTable";
import SearchFilterPanel from "components/Catalog/SearchFilterPanel/SearchFilterPanel";
import ProductsTableStub from "components/Catalog/ProductsTableStub/ProductsTableStub";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import ConfirmModal from "components/ui/modals/ConfirmModal/ConfirmModal";
import InfoCard from "components/ui/InfoCard/InfoCard";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";

import css from "./style.module.css";

// types
import { SorterResult, TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";
import {
  MyCatalogProductType,
  RequestPaginationType,
} from "app/types";
import { PaginatedImportTasksRequestType } from "importTask/types";

interface IUploadedProductsTabProps {
  form: FormInstance;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const options = [
  { label: "Портал Бизнес Коннект", value: 0 },
  { label: "Вендор Менеджмент", value: 1 }
];

const UploadedProductsTab: FC<IUploadedProductsTabProps> = ({setActiveTab}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const myCatalog: InitialMyCatalogStateType = useSelector((state: AppStateType) => state.myCatalogSlice);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowCreateCopyModal, setIsShowCreateCopyModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<MyCatalogProductType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });

  useEffect(() => {
    fetchProductsList(getRequestPagination());
  }, [paginationInfo]);

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value) => value.key));
  }, [selectedRows]);

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows: MyCatalogProductType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleError = (messageText: string, error: Error): void => {
    message.error(messageText);
    console.error(error?.message);
  };

  const { debouncedFunction: fetchProductsList } = useCancelableDebounce((params, token) => {
    const searchParams: PaginatedImportTasksRequestType = {
      ...getRequestPagination(),
      is_periodic: true,
    };

    dispatch(fetchMyCatalogList(params || searchParams, token))
      .catch((error: any) => {
        if (!axios.isCancel(error)) {
          handleError("Ошибка при загрузке списка задач.", error);
        }
      });
  });

  const getRequestPagination = (): RequestPaginationType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
    ...(sorterInfo ? { order_by: generateSortString(sorterInfo) } : {}),
  });

  const showDeleteModal = (): void => setIsShowDeleteModal(true);

  const closeDeleteModal = (): void => setIsShowDeleteModal(false);

  const deleteProducts = (): void => {
    // TODO: тут будет запрос на удаление товаров
  };

  const showCreateCopyModal = (): void => setIsShowCreateCopyModal(true);

  const closeCreateCopyModal = (): void => setIsShowCreateCopyModal(false);

  const renderFiltersPanel = (): JSX.Element => {
    return (
      <div className="flex">
        {!!selectedRows?.length && (
          <PrimaryButton
            className="mr-3"
            text={`Удалить (${selectedRows?.length})`}
            onClickHandler={showDeleteModal}
          />
        )}
        <Select
          size="large"
          options={options}
          className={`mr-3 ${css.select}`}
          defaultValue={options[0].value}
        />
        <SearchFilterPanel
          placeholder="Найти по наименованию товара"
          onSearch={(value) => console.log(value)}
        />
      </div>
    );
  };

  const renderProductActions = (_value: any, idx: number): React.ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: <a>Редактировать</a>,
        key: `edit-${idx}`,
        disabled: true
      },
      {
        label: <a>Создать копию</a>,
        key: `copy-${idx}`,
        onClick: showCreateCopyModal,
        disabled: true
      },
      {
        label: <a>Редактировать цену</a>,
        key: `edit-price-${idx}`,
        disabled: true
      },
      {
        label: <a style={{color: "#FF4D4F"}}>Удалить</a>,
        key: `delete-${idx}`,
        disabled: true,
        onClick: showDeleteModal,
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  return (
    <div>
      {renderFiltersPanel()}
      <CatalogTable
        columns={[
          "name",
          "category",
          "bk_category",
          "price",
          "status",
          "actions"
        ]}
        list={myCatalog.list}
        count={myCatalog.total}
        isFetching={myCatalog.isFetching}
        isShowEmptyBlock
        rowSelection={rowSelection}
        emptyBlock={<ProductsTableStub setActiveTab={setActiveTab} />}
        renderActions={renderProductActions}
        setPaginationInfo={setPaginationInfo}
      />
      {isShowDeleteModal && (
        <ConfirmModal
          text="Вы уверены, что хотите удалить выбранные товары?"
          isOpen={isShowDeleteModal}
          onCancelHandler={closeDeleteModal}
          onOkHandler={deleteProducts}
        />
      )}
      {isShowCreateCopyModal && (
        <ConfirmModal
          text="Вы уверены, что хотите создать копию товара?"
          isOpen={isShowCreateCopyModal}
          onCancelHandler={closeCreateCopyModal}
          onOkHandler={() => {}}
        >
          <InfoCard
            className="px-3 py-2"
            icon={{type: "exclamation", className: "mr-2"}}
            background="grey"
            text={{ content: "В начало наименования скопированного товара будет добавлена приписка “Копия”" }}
          />
        </ConfirmModal>
      )}
    </div>
  );
};

export default UploadedProductsTab;
