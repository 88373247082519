import React, { JSX, FC, useState, useEffect, Dispatch, SetStateAction } from "react";

// utils
import generateSortString from "utils/generateSortString";
import { checkObjIsEmpty } from "utils/checkObjIsEmpty";

// components
import { Table } from "antd";
import { renderColumns } from "components/Catalog/CatalogTable/columns/renderColumns";

import css from "./style.module.css";

// types
import { TableRowSelection, TablePaginationConfig, SorterResult } from "antd/es/table/interface";
import { ImportTaskType, PaginatedImportTasksRequestType } from "importTask/types";
import {
  MyCatalogProductType,
  RequestMyCatalogFiltersType,
} from "app/types";
import { CategoryType } from "categories/types";

export type EntityType = MyCatalogProductType | ImportTaskType | CategoryType;

// Типы колонок у таблицы
export type ProductColumnType =
  | "photo"
  | "name"
  | "category"
  | "bk_category"
  | "price"
  | "updated_at"
  | "download_source"
  | "status"
  | "actions"
  ;

export type CatalogColumnType =
  | "date_time"
  | "created_at"
  | "updated_at"
  | "periodicity"
  | "catalogue_type"
  | "status"
  | "source"
  | "file"
  | "actions"
  ;

export type ComparisonColumnType =
  | "category_supplier"
  | "editable_bk_category"
  | "products_count"
  ;

export type TableColumnType = ProductColumnType | CatalogColumnType | ComparisonColumnType;

type FiltersType = RequestMyCatalogFiltersType | PaginatedImportTasksRequestType;

type RenderEntityType = EntityType & {
  key: string;
}

export type EditPropsType = {
  isLoading?: boolean;
  setEditingKey?: Dispatch<SetStateAction<string>>
  setIsEditing?: (arg?: any) => boolean;
  editingKey?: string;
  edit?: (...arg: any[]) => void;
  save?: (arg?: any) => void;
}

interface ICatalogTableProps {
  columns: TableColumnType[];
  list?: EntityType[];
  count?: number;
  fetchList?: (pagination, filters) => void;
  isFetching?: boolean;
  renderActions?: (entity: any, idx: number) => React.ReactNode;
  rowSelection?: TableRowSelection<any>;
  filters?: FiltersType;
  setPaginationInfo?: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setSorterInfo?: React.Dispatch<React.SetStateAction<SorterResult<any> | SorterResult<any>[]>>;
  editProps?: EditPropsType;
  emptyBlock?: React.ReactNode; // Пустой блок: isFetching + (list.length = 0) + (count = 0) + (isShowEmptyBlock = true)
  isShowEmptyBlock?: boolean; // Показывать пустой блок и тут можно свою проверку || true (см. выше)
  emptyExcludedFilters?: string[]; // Какие фильтры не учитывать в пустом блоке
  onClickHandler?: (...args: any[]) => void;
  className?: string;
}

const CatalogTable: FC<ICatalogTableProps> = ({
  columns,
  list,
  count,
  fetchList,
  isFetching,
  rowSelection,
  renderActions,
  filters,
  setPaginationInfo,
  setSorterInfo,
  editProps,
  emptyBlock,
  onClickHandler,
  isShowEmptyBlock = false,
  emptyExcludedFilters = [],
  className = "",
}) => {
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<RenderEntityType[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: count || 0,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorter, setSorter] = useState<SorterResult<any> | SorterResult<any>[]>(null);

  const getRequestPagination = () => ({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...(sorter ? { order_by: generateSortString(sorter) } : {}),
  });

  useEffect(() => {
    if (!isFetching && !!fetchList) {
      fetchList(getRequestPagination(), filters);
    }
  }, [filters, pagination.current, pagination.pageSize, generateSortString(sorter)]);

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, current: 1, total: count ?? 0 }));
  }, [count]);

  // Проверка на пустой блок только при смене isFetching
  // Другие решения могут вызывать лишний re-render
  useEffect(() => {
    !isFetching && setShowEmpty(
      !isFetching
      && !(list?.length > 0)
      && (count ?? 0) === 0
      && checkObjIsEmpty(filters, emptyExcludedFilters)
    );
  }, [isFetching]);

  useEffect(() => {
    setProductsList(
      list?.map((entity: EntityType, idx: number): RenderEntityType => ({
        key: idx.toString(),
        ...entity,
      })) || []
    );
  }, [list]);

  useEffect((): void => {
    if (setPaginationInfo && pagination) {
      setPaginationInfo(pagination);
    }
  }, [setPaginationInfo, pagination]);

  useEffect((): void => {
    if (sorter && !!setSorterInfo) {
      setSorterInfo(sorter);
    }
  }, [setSorterInfo, sorter]);

  const onPaginationChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ): void => {
    setSorter(sorter);
    setPagination(pagination);
  };

  const renderSearchNotFound = (): JSX.Element => <>Ничего не найдено</>;

  return !!emptyBlock && isShowEmptyBlock && showEmpty ? (
    emptyBlock
  ) : (
    <div className={css.tableWrapper}>
      <Table
        loading={isFetching}
        dataSource={productsList}
        pagination={pagination}
        onChange={onPaginationChange}
        rowSelection={columns && (rowSelection || null)}
        className={`${css.table} ${className}`}
        locale={{ emptyText: renderSearchNotFound() }}
        scroll={{ y: "55vh" }}
      >
        {columns && columns.map((column: TableColumnType) => renderColumns(
          column,
          renderActions,
          onClickHandler,
          editProps
        ))}
      </Table>
    </div>
  );
};

export default CatalogTable;
